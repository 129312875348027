import React, { useState, useRef } from 'react';
// import SelectSearch from 'react-select-search';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';
import axiosInstance from '../../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import "react-time-picker-input/dist/components/TimeInput.css";
import { format } from 'date-fns';
import { showError } from '../../../utils/ErrorHelper';

const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));


const ModifierSalarieAbsence = (props) => {
    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    // const [salarieSelectedId, setSalarieSelectedId] = useState(props.jourSalarie.salarieId);
    // const [salarieSelectedStatut, setSalarieSelectedStatut] = useState(props.jourSalarie.statut);
    const commentaire = useRef();

    // const handleChangeSalarie = (...args) => {
    //     // console.log("ARGS:", args);
    //     // console.log("CHANGE:");
    //     setSalarieSelectedId(args[0])
    //     setSalarieSelectedStatut(args[1].groupName)
    // };
    // const searchSalarieInputModify = useRef();

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onModifySalarieAbsence = () => {
        setButtonDisabled(true);
        const dateJour = format(new Date(props.jourSalarie.absence.date_deb_absence), "yyyy-MM-dd");
        let postValueAbsence = {
            'date_deb_absence': dateJour,
            'date_fin_absence': dateJour,
            'commentaire': commentaire.current.value,
        };
        axiosInstance.patch(`/absences/releveAbsence/${props.jourSalarie.absence.utilisateur_absent._id}&${dateJour}`, postValueAbsence)
            .then((result) => {
                customToast.success("L'absence a été modifié avec succès");
                dispatch(loadHoraireOnChange(props.annee, props.numeroSemaine));
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
    };

    let fullName = props.jourSalarie.absence.utilisateur_absent.nom + " " + props.jourSalarie.absence.utilisateur_absent.prenom;

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier l'absence de {fullName} le {format(new Date(props.jourSalarie.absence.date_deb_absence), "dd/MM/yyyy")}</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                {/* <label className='modifier-form-label default-label' htmlFor="immatriculation">Sélectionner un salarie</label> */}
                {/* <SelectSearch
                        ref={searchSalarieInputModify}
                        options={props.jourSalarie.optionsSalarie}
                        filterOptions={props.jourSalarie.searchFilter}
                        value={props.jourSalarie.salarieId}
                        name="salarie"
                        placeholder="Choisir un salarie"
                        search
                        onChange={handleChangeSalarie}
                /> */}
                <label className="default-label">Commentaire</label>
                <input ref={commentaire} type="text" className='default-input commentaire-input' defaultValue={props.jourSalarie.absence.commentaire} placeholder="Commentaire" />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => onModifySalarieAbsence()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierSalarieAbsence;