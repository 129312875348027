import React, { useEffect, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';

const NewTableRender = (props) => {

    const columns = useMemo(() => props.columns, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable({
        columns,
        data: props.tableData,
        manualSortBy: true,
        initialState: {
            sortBy: props.initialSort
        }
    },
        useSortBy);

    useEffect(() => {
        console.log("test");
        props.fetchData(sortBy);
    }, [sortBy]);

    return (
        <div className="table-container">
            <table className="default-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <Icon className='mgL-s2' icon="fa:sort-down" />
                                                : <Icon className='mgL-s2' icon="fa:sort-asc" />
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default NewTableRender;