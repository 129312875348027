import React, { useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { maxByPorteeZone, truncateString } from '../../utils/GeneralHelper';
import { Icon } from '@iconify/react';
import ReleveHeureListFormV2 from './ReleveHeureListFormV2';
import ReleveAbsenceListFormV2 from './ReleveAbsenceListFormV2';
import ReleveHeureDotMenuV2 from './ReleveHeureDotMenuV2';

const ReleveJour = (props) => {

    const [isJourOpen, setIsJourOpen] = useState(false);
    const [isChantierAjouterSupplementaire, setIsChantierAjouterSupplementaire] = useState(false);

    const jourKeyFormated = format(props.dateJour, "yyyy-MM-dd");

    const openJourAction = (event) => {
        if (event.currentTarget.childNodes.length === 0 || event.currentTarget.childNodes[0].tagName !== "BUTTON") {
            setIsJourOpen(!isJourOpen);
        }
    };

    const getChantier = (chantierId) => {
        for (const chantier of props.listeDesChantiers) {
            if (chantierId === chantier._id._id) {
                return chantier;
            }
        }
        return null;
    };

    const getAllChantiersInCurrentDay = () => {
        let allChantiersInCurrentDay = [];
        if (props.jour && props.jour.hasOwnProperty("travails")) {
            for (const chantier of Object.keys(props.jour.travails)) {
                let chantierData = getChantier(chantier);
                if (chantierData) {
                    allChantiersInCurrentDay.push(chantierData);
                }
            }
        }
        return allChantiersInCurrentDay;
    };

    const chantierArrayTruncateString = (chantierArray) => {
        let truncatedLibelleChantier = [];
        for (const chantier of chantierArray) {
            truncatedLibelleChantier.push(truncateString(chantier._id.libelle_lieu, 12));
        }
        return truncatedLibelleChantier.join(', ');
    };

    const getNombreHeureSaisies = () => {
        let totalHeureSaisies = 0;
        if (props.jour && props.jour.hasOwnProperty("travails")) {
            for (const chantier of Object.keys(props.jour.travails)) {
                totalHeureSaisies += props.jour.travails[chantier].length;
            }
        }
        return totalHeureSaisies;
    };

    const getNombreAbsences = () => {
        if (props.jour && props.jour.hasOwnProperty("absences")) {
            return props.jour.absences.length;
        }
        return 0;
    };

    const existTravailHoraireVide = () => {
        if (props.jour && props.jour.hasOwnProperty("travails")) {
            for (const chantier of Object.keys(props.jour.travails)) {
                for (const travailSalarie of props.jour.travails[chantier]) {
                    if ((Object.values(travailSalarie.horaireAprem)).every(heure => heure === null) && (Object.values(travailSalarie.horaireMat)).every(heure => heure === null)) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    return (
        <>
            <tr className={isJourOpen ? "jourTableRow RowActive borderBottom" : "jourTableRow borderBottom"}>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{format(props.dateJour, "dd/MM")}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{getAllChantiersInCurrentDay().length ? chantierArrayTruncateString(getAllChantiersInCurrentDay()) : <p>Aucun chantier</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{getAllChantiersInCurrentDay().length ? maxByPorteeZone(getAllChantiersInCurrentDay()) : <p>Aucune zone</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{getNombreHeureSaisies() + getNombreAbsences()}{getNombreAbsences() ? ` dont ${getNombreAbsences()} absence${getNombreAbsences() > 1 ? 's' : ''}` : ''}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{props.statutSemaine > 0 ? <div className='dotAndText'><Icon icon="bxs:lock" className='verrou' /><p>Verrouiller</p></div> : getNombreHeureSaisies() === 0 && getNombreAbsences() === 0 ? <div className='dotAndText'><Icon className="redDot dot" icon="carbon:dot-mark" /><p>Aucune saisie</p></div> : existTravailHoraireVide() ? <div className='dotAndText'><Icon icon="gg:danger" className='danger' /><p>Certaines horaires sont incomplètes</p></div> : <div className='dotAndText'><Icon className="yellowDot dot" icon="carbon:dot-mark" /><p>En cours</p></div>}</td>
                <td className='noBorderBottom' style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}><ReleveHeureDotMenuV2 jourDate={jourKeyFormated} openSupprimerTouteHeures={props.openSupprimerTouteHeures} openCopierVers={props.openCopierVers} openSupprimerTouteHeuresChantier={props.openSupprimerTouteHeuresChantier} statutSemaine={props.statutSemaine} allDays={props.allDays} chantiers={getAllChantiersInCurrentDay()} /><div onClick={(e) => openJourAction(e)} style={{ flex: 1 }} className='transparentContainer'></div></td>
            </tr>
            {props.jour !== null && props.jour.hasOwnProperty("travails") && Object.keys(props.jour.travails).length
                ? Object.keys(props.jour.travails).map((chantierId) => {
                    return (
                        <ReleveHeureListFormV2 ajouterHeure={props.ajouterHeure} saisiePar={props.saisiePar} openModifierSalarieHeure={props.openModifierSalarieHeure} openDeleteHeure={props.openDeleteHeure} openModifierChantier={props.openModifierChantier} statutSemaine={props.statutSemaine} travails={props.jour.travails[chantierId]} listeDesSalaries={props.listeDesSalaries} listeDesChantiers={props.listeDesChantiers} key={chantierId + jourKeyFormated} chantierId={chantierId} open={isJourOpen} jourDate={jourKeyFormated} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} />
                    );
                })
                : <ReleveHeureListFormV2 statutSemaine={props.statutSemaine} ajouterHeure={props.ajouterHeure} saisiePar={props.saisiePar} listeDesSalaries={props.listeDesSalaries} listeDesChantiers={props.listeDesChantiers} chantierId={null} open={isJourOpen} jourDate={jourKeyFormated} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} />
            }

            {isChantierAjouterSupplementaire && <ReleveHeureListFormV2 statutSemaine={props.statutSemaine} ajouterHeure={props.ajouterHeure} saisiePar={props.saisiePar} listeDesSalaries={props.listeDesSalaries} chantierId={null} listeDesChantiers={props.listeDesChantiers} open={isJourOpen} jourDate={jourKeyFormated} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} />}

            {isJourOpen ?
                <tr>
                    <td className="ajouterChantier-buttonContainer" colSpan="6" style={{ border: "none" }}>
                        <button disabled={(isChantierAjouterSupplementaire || props.jour === null) || (props.jour.hasOwnProperty("travails") && Object.keys(props.jour.travails).length === 0) ? true : false} className={(isChantierAjouterSupplementaire || props.jour === null) || (props.jour.hasOwnProperty("travails") && Object.keys(props.jour.travails).length === 0) ? "validate-button-fw disabled-button ajouterChantier-button" : "validate-button-fw ajouterChantier-button"} onClick={() => setIsChantierAjouterSupplementaire(true)} value="AddChantier">Ajouter un chantier</button>
                    </td>
                </tr>
                : null}

            <ReleveAbsenceListFormV2 ajouterAbsence={props.ajouterAbsence} saisiePar={props.saisiePar} open={isJourOpen} openSupprimerAbsence={props.openSupprimerAbsence} statutSemaine={props.statutSemaine} listeDesSalaries={props.listeDesSalaries} jourDate={jourKeyFormated} absences={props.jour !== null && props.jour.hasOwnProperty("absences") ? props.jour.absences : []} />

        </>

    );
};

export default ReleveJour;
