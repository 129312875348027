import React, { useState, useRef } from 'react';
import SelectSearch from 'react-select-search';
import customToast from '../../utils/ToastifyHelper';
import * as actionCreators from '../../store/actions/exportAction';
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { showError } from '../../utils/ErrorHelper';


const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));
const updateChantier = (annee, numero_semaine, newSemaine) => (actionCreators.updateReleveHeureAdmin(annee, numero_semaine, newSemaine));

const ModifierChantier = (props) => {
    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [chantierSelectedId, setChantierSelectedId] = useState(null);

    const searchChantierInputModify = useRef();
    const handleChangeChantier = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE:");
        // setFormErrorSalarie(false)
        setChantierSelectedId(args[0]);
    };

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const handleFilterChantier = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return props.jourChantier.optionsChantier;
            }
            const newItems = items.filter((item) => {
                return (item.searchCode.toLowerCase().includes(searchValue.toLowerCase()) || item.searchNom.toLowerCase().includes(searchValue.toLowerCase()));
            });
            return newItems;
        };
    };

    const onModifyChantier = () => {
        setButtonDisabled(true);
        if (chantierSelected === null || props.jourChantier.oldChantierId === chantierSelected.value) {
            customToast.error("Vous n'avez pas modifié le chantier");
            setButtonDisabled(false);
        }
        else {
            let postData = { chantier: chantierSelected.value, oldChantierId: props.jourChantier.oldChantierId, date_jour: props.jourChantier.date_jour };
            if (props.userRole === "admin") {
                axiosInstance.patch(`/horaires_semaines/modifierChantier/${props.chefChantierId}&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        customToast.success("Le chantier a été modifié avec succès");
                        dispatch(updateChantier(props.annee, props.numeroSemaine, result.data));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
            else {
                axiosInstance.patch(`/horaires_semaines/modifierChantier/me&${props.annee}&${props.numeroSemaine}`, postData)
                    .then((result) => {
                        customToast.success("Le chantier a été modifié avec succès");
                        dispatch(loadHoraireOnChange(props.annee, props.numeroSemaine));
                        close();
                    }).catch((err) => {
                        setButtonDisabled(false);
                        showError(err);
                    });
            }
        }
    };

    const allChantierOptions = useSelector(state => state.chantierZoneList.chantierFormatOptions);
    const [chantierSelected, setChantierSelected] = useState(null);

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier le chantier</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                <label className='modifier-form-label default-label' htmlFor="immatriculation">Sélectionner un chantier</label>
                <Select
                    options={allChantierOptions}
                    name="selectChantier"
                    className="formSelect-search-container mgB-s2"
                    classNamePrefix="formSelect-search"
                    onChange={(opt) => setChantierSelected(opt)}
                    placeholder="Sélectionner le chantier"
                    isSearchable={true}
                    defaultValue={allChantierOptions.find(chantierOpt => chantierOpt.value === props.jourChantier.oldChantierId)}
                />
                {/* <SelectSearch
                    ref={searchChantierInputModify}
                    options={props.jourChantier.optionsChantier}
                    filterOptions={handleFilterChantier}
                    value={props.jourChantier.oldChantierId}
                    name="chantier"
                    placeholder="Rechercher un chantier"
                    search
                    onChange={handleChangeChantier}
                /> */}
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => onModifyChantier()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierChantier;