import React from "react";
import { getProcessusMax } from "../../../utils/Helper/ProcessusHelper";
import EditIcon from "../../icon/EditIcon";
import SupprimerIcon from "../../icon/SupprimerIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { formatBytes, iconExtension } from "../../../utils/GeneralHelper";
import { Icon } from "@iconify/react";

// Get zone chantier amiante
export const getZonesChantiersAmianteColumns = (dateDebutChantier, openModifierZoneAmianteModal, openSupprimerZoneAmianteModal) => {
    return [
        {
            Header: "Nom de la zone",
            accessor: "nomZoneAmiante",
        },
        {
            Header: "Environnement",
            accessor: "environnement",
        },
        {
            Header: "Phase",
            accessor: "phase",
        },
        {
            Header: "Processus",
            accessor: (row) => {
                const processusMax = getProcessusMax(row.processus, dateDebutChantier);
                return processusMax.concentration.rc;
            },
            Cell: ({ row }) => {
                const processusMax = getProcessusMax(row.original.processus, dateDebutChantier);
                return (
                    <>
                        <p>{row.original.processus.map((processus) => {
                            return processus.codeProcessus;
                        }).join(", ")}</p>
                        <p>{`Concentration attendue: ${processusMax.concentration.rc} f/L - Niveau ${processusMax.processus.niveauEmpoussierement}`}</p>
                    </>
                );
            }
        },
        {
            Header: "Encadrant de chantier",
            accessor: (row) => {
                return `${row.encadrantDeChantier.prenom} ${row.encadrantDeChantier.nom}`;
            },
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierZoneAmianteModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerZoneAmianteModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getReleveHeureParChefColumns = (
    navigate
) => {
    return [
        {
            Header: "Nom",
            accessor: "nom"
        },
        {
            Header: "Prénom",
            accessor: "prenom"
        },
        {
            Header: "Matricule",
            accessor: "login_matricule"
        },
        {
            Header: "Semaines validées",
            accessor: "statutSemaine0"
        },
        {
            Header: "Semaines en cours",
            accessor: "statutSemaine1"
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => {
                            navigate(`/releve-heure/releve-chef/${row.original._id}`);
                        }}
                        className="select-semaine-button default-radius"
                    >
                        <Icon icon="fluent:clipboard-search-24-regular" />
                    </button>
                </div >
            ),
        }
    ];
};

export const getReleveHeureParSemaineColumns = (openSalariePresentSemaine, navigate) => {
    return [
        {
            Header: "Année",
            accessor: "annee"
        },
        {
            Header: "Numéro semaine",
            accessor: "numeroSemaine"
        },
        {
            Header: "Chef de chantier",
            id: "nom_prenom",
            Cell: ({ row }) => (
                <div className="tableTdContainer">
                    <p>
                        {row.original.saisiePar.nom}{" "}
                        {row.original.saisiePar.prenom}
                    </p>
                </div>
            ),
        },
        {
            Header: "Statut",
            accessor: "statutSemaine",
            Cell: ({ row }) =>
                row.original.statutSemaine === 0 ? (
                    <div className="dotAndText">
                        <Icon className="yellowDot dot" icon="carbon:dot-mark" />
                        <p>En cours</p>
                    </div>
                ) : (
                    <div className="dotAndText">
                        <Icon className="greenDot dot" icon="carbon:dot-mark" />
                        <p>Terminé</p>
                    </div>
                ),
        },
        {
            Header: "Actions",
            disableFilters: true,
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openSalariePresentSemaine(row.original._id)}
                        className="select-semaine-button default-radius mgR-s2"
                    >
                        <Icon icon="mdi:user-outline" />
                    </button>
                    <button
                        onClick={() => navigate(`/releve-heure/edit-releve/${row.original._id}`)}
                        className="select-semaine-button default-radius"
                    >
                        <Icon icon="fluent:clipboard-search-24-regular" />
                    </button>
                </div>
            )
        }
    ];
};

// Get tableau intervenants tiers
export const getIntervenantsTiersColumns = (
    openModifierIntervenantsTiersModal, openSupprimerIntervenantsTiersModal
) => {
    return [
        {
            Header: "Catégorie",
            accessor: "categorieIntervenant"
        },
        {
            Header: "Intervenant",
            accessor: "libelleIntervenant"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Téléphone",
            accessor: "telephones",
            Cell: ({ row }) => (
                <p>{row.original.telephones.join(", ")}</p>
            )
        },
        {
            Header: "Adresse",
            accessor: (row) => {
                return `${row.adresse.adresse !== null ? row.adresse.adresse + " " : ""}${row.adresse.hasOwnProperty("cs") && row.adresse.cs !== null ? "CS " + row.adresse.cs + " " : ""}${row.adresse.codePostal !== null ? row.adresse.codePostal + " " : ""}${row.adresse.ville !== null ? row.adresse.ville + " " : ""}`;
            },
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierIntervenantsTiersModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerIntervenantsTiersModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getFichiersChantierColumns = (
    openSupprimerFichierModal,
    openModifierFichierModal,
    viewFile
) => {
    return [
        {
            id: "IconFichier",
            header: () => null,
            Cell: ({ row }) => {
                return (
                    <FontAwesomeIcon className="fileIcon" icon={faFile} />
                );
            }
        },
        {
            Header: "Nom du fichier",
            accessor: "nomFichier"
        },
        {
            Header: "Taille",
            accessor: "_id.length",
            Cell: ({ row }) => {
                return (
                    <p>{formatBytes(row.original._id.length)}</p>
                );
            }
        },
        {
            Header: "Crée par",
            accessor: (row) => {
                return `${row.auteur.prenom} ${row.auteur.nom}`;
            },
        },
        {
            Header: "Visible par",
            accessor: "visibility",
            Cell: ({ row }) => (
                <p>{row.original.visibility.join(", ")}</p>
            )
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierFichierModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => viewFile(row.original._id._id)}
                        className="select-semaine-button default-radius mgR-s2"
                    >
                        <Icon icon="mdi:file-document-outline" />
                    </button>
                    <button
                        onClick={() => openSupprimerFichierModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getCodeFamilleDechetColumns = (openSupprimerCodeFamilleModal, openModifierCodeFamilleModal) => {
    return [
        {
            Header: "Code famille",
            accessor: "value",
        },
        {
            Header: "Description",
            accessor: "label",
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierCodeFamilleModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerCodeFamilleModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getCodeCedDechetColumns = (openSupprimerCodeCedModal, openModifierCodeCedModal) => {
    return [
        {
            Header: "Code famille",
            accessor: "value",
        },
        {
            Header: "Description",
            accessor: "label",
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierCodeCedModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerCodeCedModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getConditionnementColumns = (openModifierConditionnementModal, openSupprimerConditionnementModal) => {
    return [
        {
            Header: "Type de conditionnement",
            accessor: "value",
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierConditionnementModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerConditionnementModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getGestionDechetColumns = (openModifierDechetModal, openSupprimerDechetModal) => {
    return [
        {
            Header: "Déchet",
            accessor: "libelleDechet",
        },
        {
            Header: "Code famille",
            accessor: "codeFamille",
        },
        {
            Header: "Code CED",
            accessor: "codeCed",
        },
        {
            Header: "Type de conditionnement",
            accessor: "conditionnement",
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => openModifierDechetModal(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => openSupprimerDechetModal(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};

export const getDechetChantierAmianteColumns = (viewFile) => {
    return [
        {
            Header: "Déchet",
            accessor: "dechet.libelleDechet",
            Cell: ({ row }) => (
                <p>{row.original.dechet.libelleDechet} - (famille: {row.original.dechet.codeFamille}, CED: {row.original.dechet.codeCed})</p>
            )
        },
        {
            Header: "Quantité estimée",
            accessor: "quantite",
        },
        {
            Header: "Zone",
            accessor: "zoneAmiante",
            Cell: ({ row }) => (
                <p>{row.original.zoneAmiante.nomZoneAmiante} - ({row.original.zoneAmiante.environnement})</p>
            )
        },
        {
            Header: "Fid",
            accessor: (row) => {
                if (row.fid.length) {
                    return row.fid[0]._id;
                }
                return null;
            },
            //sort method
            Cell: ({ row }) => {
                if (row.original.fid.length) {
                    return (<p onClick={() => viewFile(row.original.fid[0].fichierFid._id._id)} className="tableFileLink">
                        {iconExtension(row.original.fid[0].fichierFid._id.filename)}
                        {row.original.fid[0].fichierFid.nomFichier.replace(/_([^_]*)$/, '')}
                        .{row.original.fid[0].fichierFid._id.filename.split(".")[row.original.fid[0].fichierFid._id.filename.split(".").length - 1]}
                    </p>);
                }
                return null;
            }
        },
        {
            Header: "Cap",
            accessor: (row) => {
                if (row.cap.length) {
                    return row.cap[0]._id;
                }
                return null;
            },
            //sort method
            Cell: ({ row }) => {
                if (row.original.cap.length) {
                    return (<p onClick={() => viewFile(row.original.cap[0].fichierCap._id._id)} className="tableFileLink">
                        {iconExtension(row.original.cap[0].fichierCap._id.filename)}
                        {row.original.cap[0].fichierCap.nomFichier.replace(/_([^_]*)$/, '')}
                        .{row.original.cap[0].fichierCap._id.filename.split(".")[row.original.cap[0].fichierCap._id.filename.split(".").length - 1]}
                    </p>);
                }
                return null;
            }
        },
        {
            Header: "BSD",
            accessor: (row) => {
                if (row.bsda.fichierBsda) {
                    return row.bsda.fichierBsda._id._id;
                }
                return null;
            },
            //sort method
            Cell: ({ row }) => {
                if (row.original.bsda.fichierBsda) {
                    return (<p onClick={() => viewFile(row.original.bsda.fichierBsda._id._id)} className="tableFileLink">
                        {iconExtension(row.original.bsda.fichierBsda._id.filename)}
                        {row.original.bsda.fichierBsda.nomFichier.replace(/_([^_]*)$/, '')}
                        .{row.original.bsda.fichierBsda._id.filename.split(".")[row.original.bsda.fichierBsda._id.filename.split(".").length - 1]}
                    </p>);
                }
                return null;
            }
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <div className="table-buton-container">
                    <button
                        onClick={() => console.log(row.original)}
                        className="table-modify-button"
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={() => console.log(row.original)}
                        className="table-delete-button"
                    >
                        <SupprimerIcon />
                    </button>
                </div>
            )
        }
    ];
};