import React, { useState, useRef, useEffect } from 'react';
// import SelectSearch from 'react-select-search';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';
import axiosInstance from '../../../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import TimePicker from 'react-time-picker-input';
import "react-time-picker-input/dist/components/TimeInput.css";
import { formatIntTwoDigitString, getTotalHeure } from '../../../utils/GeneralHelper';
import { format } from 'date-fns';
import { showError } from '../../../utils/ErrorHelper';

const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));

const ModifierSalarieHeure = (props) => {
    const dispatch = useDispatch();
    const totalHeureCalc = getTotalHeure({
        horaireAprem: { heureDeb: props.jourSalarie.travail.horaireAprem.heureDeb, minuteDeb: props.jourSalarie.travail.horaireAprem.minuteDeb, heureFin: props.jourSalarie.travail.horaireAprem.heureFin, minuteFin: props.jourSalarie.travail.horaireAprem.minuteFin },
        horaireMat: { heureDeb: props.jourSalarie.travail.horaireMat.heureDeb, minuteDeb: props.jourSalarie.travail.horaireMat.minuteDeb, heureFin: props.jourSalarie.travail.horaireMat.heureFin, minuteFin: props.jourSalarie.travail.horaireMat.minuteFin }
    });
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    // const [salarieSelectedId, setSalarieSelectedId] = useState(props.jourSalarie.salarieId);
    // const [salarieSelectedStatut, setSalarieSelectedStatut] = useState(props.jourSalarie.statut);
    const commentaire = useRef();
    const [horaireMatinDeb, sethoraireMatinDeb] = useState(props.jourSalarie.travail.horaireMat.heureDeb !== null && props.jourSalarie.travail.horaireMat.minuteDeb !== null ? formatIntTwoDigitString(props.jourSalarie.travail.horaireMat.heureDeb) + ":" + formatIntTwoDigitString(props.jourSalarie.travail.horaireMat.minuteDeb) : "--:--");
    const [horaireMatinFin, sethoraireMatinFin] = useState(props.jourSalarie.travail.horaireMat.heureFin !== null && props.jourSalarie.travail.horaireMat.minuteFin !== null ? formatIntTwoDigitString(props.jourSalarie.travail.horaireMat.heureFin) + ":" + formatIntTwoDigitString(props.jourSalarie.travail.horaireMat.minuteFin) : "--:--");
    const [horaireApremDeb, sethoraireApremDeb] = useState(props.jourSalarie.travail.horaireAprem.heureDeb !== null && props.jourSalarie.travail.horaireAprem.minuteDeb !== null ? formatIntTwoDigitString(props.jourSalarie.travail.horaireAprem.heureDeb) + ":" + formatIntTwoDigitString(props.jourSalarie.travail.horaireAprem.minuteDeb) : "--:--");
    const [horaireApremFin, setHoraireApremFin] = useState(props.jourSalarie.travail.horaireAprem.heureFin !== null && props.jourSalarie.travail.horaireAprem.minuteFin !== null ? formatIntTwoDigitString(props.jourSalarie.travail.horaireAprem.heureFin) + ":" + formatIntTwoDigitString(props.jourSalarie.travail.horaireAprem.minuteFin) : "--:--");
    const [totalHeure, setTotalHeure] = useState(totalHeureCalc.heure !== 0 && totalHeureCalc.minute !== 0 ? formatIntTwoDigitString(totalHeureCalc.heure) + ":" + formatIntTwoDigitString(totalHeureCalc.minute) : "--:--");

    useEffect(() => {
        const tryUpdateTotalHeure = () => {
            const heureMatinDebut = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const heureMatinFin = horaireMatinFin.match(/^(\d+):(\d+)/);
            const heureApremDebut = horaireApremDeb.match(/^(\d+):(\d+)/);
            const heureApremFin = horaireApremFin.match(/^(\d+):(\d+)/);
            if (heureMatinDebut !== null && heureMatinFin !== null && heureApremDebut !== null && heureApremFin !== null) {
                let totalHeureMatin = Math.abs(parseInt(heureMatinFin[1]) - parseInt(heureMatinDebut[1]));
                let totalMinuteMatin = parseInt(heureMatinFin[2]) - parseInt(heureMatinDebut[2]);
                let totalHeureAprem = Math.abs(parseInt(heureApremFin[1]) - parseInt(heureApremDebut[1]));
                let totalMinuteAprem = parseInt(heureApremFin[2]) - parseInt(heureApremDebut[2]);
                let totalHeure = totalHeureMatin + totalHeureAprem;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin + totalMinuteAprem;
                const quotientMinute = Math.floor(totalMinute / 60);
                if (quotientMinute >= 1) {
                    totalHeure += quotientMinute;
                    totalMinute = Math.floor(totalMinute % 60);
                }

                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (heureMatinDebut !== null && heureMatinFin !== null && heureApremDebut === null && heureApremFin === null) {
                let totalHeureMatin = Math.abs(parseInt(heureMatinFin[1]) - parseInt(heureMatinDebut[1]));
                let totalMinuteMatin = parseInt(heureMatinFin[2]) - parseInt(heureMatinDebut[2]);
                let totalHeure = totalHeureMatin;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (heureMatinDebut === null && heureMatinFin === null && heureApremDebut !== null && heureApremFin !== null) {
                let totalHeureAprem = Math.abs(parseInt(heureApremFin[1]) - parseInt(heureApremDebut[1]));
                let totalMinuteAprem = parseInt(heureApremFin[2]) - parseInt(heureApremDebut[2]);
                let totalHeure = totalHeureAprem;
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteAprem;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else {
                ;
            }
        };
        tryUpdateTotalHeure();
    }, [horaireMatinDeb, horaireMatinFin, horaireApremDeb, horaireApremFin]);

    // const handleChangeSalarie = (...args) => {
    //     // console.log("ARGS:", args);
    //     // console.log("CHANGE:");
    //     setSalarieSelectedId(args[0])
    //     setSalarieSelectedStatut(args[1].groupName)
    // };
    // const searchSalarieInputModify = useRef();

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onModifySalarie = () => {
        setButtonDisabled(true);
        const heureMatinDebut = horaireMatinDeb.match(/^(\d+):(\d+)/);
        const heureMatinFin = horaireMatinFin.match(/^(\d+):(\d+)/);
        const heureApremDebut = horaireApremDeb.match(/^(\d+):(\d+)/);
        const heureApremFin = horaireApremFin.match(/^(\d+):(\d+)/);
        if ((heureMatinDebut !== null && heureMatinFin !== null) || (heureApremDebut !== null && heureApremFin !== null)) {
            let heureMatDeb = null;
            let minuteMatDeb = null;
            let heureMatFin = null;
            let minuteMatFin = null;
            let heureApremDeb = null;
            let minuteApremDeb = null;
            let heureAprFin = null;
            let minuteAprFin = null;
            if (heureMatinDebut !== null && heureMatinFin !== null) {
                heureMatDeb = parseInt(heureMatinDebut[1]);
                minuteMatDeb = parseInt(heureMatinDebut[2]);
                heureMatFin = parseInt(heureMatinFin[1]);
                minuteMatFin = parseInt(heureMatinFin[2]);
            }
            if (heureApremDebut !== null && heureApremFin !== null) {
                heureApremDeb = parseInt(heureApremDebut[1]);
                minuteApremDeb = parseInt(heureApremDebut[2]);
                heureAprFin = parseInt(heureApremFin[1]);
                minuteAprFin = parseInt(heureApremFin[2]);
            }
            let postValueTravaux = {
                'commentaire': commentaire.current.value,
                'heureMatDeb': heureMatDeb,
                'minuteMatDeb': minuteMatDeb,
                'heureMatFin': heureMatFin,
                'minuteMatFin': minuteMatFin,
                'heureApremDeb': heureApremDeb,
                'minuteApremDeb': minuteApremDeb,
                'heureApremFin': heureAprFin,
                'minuteApremFin': minuteAprFin,
            };
            let postUrl = "";
            switch (props.jourSalarie.statut) {
                case "Ouvrier":
                    postUrl = "/travails_ouvriers";
                    break;
                case "Interimaire":
                    postUrl = "/travails_interimaires";
                    break;
                case "Chef de chantier":
                    postUrl = "/diriger_chantiers_classique";
                    break;
                default:
                    break;
            }
            axiosInstance.patch(postUrl + "/" + props.jourSalarie.travail._id, postValueTravaux)
                .then((result) => {
                    customToast.success("Les heures ont été modifié avec succès");
                    dispatch(loadHoraireOnChange(props.annee, props.numeroSemaine));
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez compléter les heures du salarié");
            setButtonDisabled(false);
        }
    };

    let fullName = "";
    switch (props.jourSalarie.statut) {
        case "Ouvrier":
            fullName = props.jourSalarie.travail.ouvrier._id.nom + " " + props.jourSalarie.travail.ouvrier._id.prenom;
            break;
        case "Interimaire":
            fullName = props.jourSalarie.travail.interimaire._id.nom + " " + props.jourSalarie.travail.interimaire._id.prenom;
            break;
        case "Chef de chantier":
            fullName = props.jourSalarie.travail.chef_chantier._id.nom + " " + props.jourSalarie.travail.chef_chantier._id.prenom;
            break;
        default:
            break;
    }

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier le relevé d'heure de {fullName} le {format(new Date(props.jourSalarie.travail.date_jour), "dd/MM/yyyy")}</h3>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <div className="vertical-form scrollable">
                {/* <label className='modifier-form-label default-label' htmlFor="immatriculation">Sélectionner un salarie</label> */}
                {/* <SelectSearch
                        ref={searchSalarieInputModify}
                        options={props.jourSalarie.optionsSalarie}
                        filterOptions={props.jourSalarie.searchFilter}
                        value={props.jourSalarie.salarieId}
                        name="salarie"
                        placeholder="Choisir un salarie"
                        search
                        onChange={handleChangeSalarie}
                /> */}
                <div className="modifyHeure-container">
                    <div className="heureModify-inputLabel">
                        <label className="default-label" htmlFor="select-heure-matinDeb">Matin</label>
                        <div className="heureMatin-modify">
                            <TimePicker name='select-horaire-matinDeb' value={horaireMatinDeb} allowDelete onChange={(dateString) => sethoraireMatinDeb(dateString)} />
                            <p>-</p>
                            <TimePicker name='select-horaire-matinFin' value={horaireMatinFin} allowDelete onChange={(dateString) => sethoraireMatinFin(dateString)} />
                        </div>
                    </div>
                    <div className="heureModify-inputLabel">
                        <label className="default-label" htmlFor="select-heure-apremDeb">Après-midi</label>
                        <div className="heureAprem-modify">
                            <TimePicker name='select-horaire-apremDeb' value={horaireApremDeb} allowDelete onChange={(dateString) => sethoraireApremDeb(dateString)} />
                            <p>-</p>
                            <TimePicker name='select-horaire-apremFin' value={horaireApremFin} allowDelete onChange={(dateString) => setHoraireApremFin(dateString)} />
                        </div>
                    </div>
                    <div className="heureModify-total">
                        <label className="default-label" htmlFor="select-heure-total">Total</label>
                        <div className="heureTotal-modify">
                            <TimePicker name='select-horaire-Total' value={totalHeure} disabled />
                        </div>
                    </div>
                </div>
                <label className="default-label">Commentaire</label>
                <input ref={commentaire} type="text" className='default-input commentaire-input' defaultValue={props.jourSalarie.travail.commentaire} placeholder="Commentaire" />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <button disabled={isButtonDisabled} className="default-button modifier-form-valider" onClick={() => onModifySalarie()}>Valider</button>
                </div>
            </div>
        </div>
    );
};

export default ModifierSalarieHeure;