import React, { useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { dateIndexInJours } from '../../utils/DateHelper';
import ReleveHeureListAndForm from './ReleveHeureListAndForm';
import { maxByPorteeZone, truncateString } from '../../utils/GeneralHelper';
import ReleveHeureDotMenu from '../ReleveHeureDotMenu';
import { Icon } from '@iconify/react';



const JourReleveHeure = (props) => {
    const joursState = useSelector(
        state => state.releveHeure.jours
    );

    const [isJourOpen, setIsJourOpen] = useState(false);
    const [isChantierAjouterSupplementaire, setIsChantierAjouterSupplementaire] = useState(false);

    const jourKeyFormated = format(props.dateJour, "yyyy-MM-dd");
    const jourIndex = dateIndexInJours(jourKeyFormated, joursState);

    const chantierArrayTruncateString = (chantierArray) => {
        let truncatedLibelleChantier = [];
        for (const chantier of chantierArray) {
            truncatedLibelleChantier.push(truncateString(chantier._id.libelle_lieu, 12));
        }
        return truncatedLibelleChantier.join(', ');
    };

    const openJourAction = (event) => {
        if (event.currentTarget.childNodes.length === 0 || event.currentTarget.childNodes[0].tagName !== "BUTTON") {
            setIsJourOpen(!isJourOpen);
        }
    };

    const jourHorairesNull = () => {
        for (const travailOuvrier of joursState[jourIndex].travail_ouvriers) {
            if ((Object.values(travailOuvrier.horaireAprem)).every(heure => heure === null) && (Object.values(travailOuvrier.horaireMat)).every(heure => heure === null)) {
                return true; 
            }
        }
        for (const travailChefChantier of joursState[jourIndex].travail_chefs_chantier) {
            if ((Object.values(travailChefChantier.horaireAprem)).every(heure => heure === null) && (Object.values(travailChefChantier.horaireMat)).every(heure => heure === null)) {
                return true;
            }
        }
        for (const travailInterimaire of joursState[jourIndex].travail_interimaires) {
            if ((Object.values(travailInterimaire.horaireAprem)).every(heure => heure === null) && (Object.values(travailInterimaire.horaireMat)).every(heure => heure === null)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <tr className={isJourOpen ? "jourTableRow RowActive borderBottom" : "jourTableRow borderBottom"}>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{format(props.dateJour, "dd/MM")}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex !== -1 && joursState[jourIndex].chantier.length ? chantierArrayTruncateString(joursState[jourIndex].chantier) : <p>Aucun chantier</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex !== -1 && joursState[jourIndex].chantier.length ? maxByPorteeZone(joursState[jourIndex].chantier) : <p>Aucune zone</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex === -1 ? <p>0</p> : joursState[jourIndex].travail_chefs_chantier.length + joursState[jourIndex].travail_ouvriers.length + joursState[jourIndex].travail_interimaires.length}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{props.statutSemaine > 0 ? <div className='dotAndText'><Icon icon="bxs:lock" className='verrou' /><p>Verrouiller</p></div> : jourIndex === -1 ? <div className='dotAndText'><Icon className="redDot dot" icon="carbon:dot-mark" /><p>Aucune saisie</p></div> : jourHorairesNull() ? <div className='dotAndText'><Icon icon="gg:danger" className='danger' /><p>Certaines horaires sont incomplètes</p></div> : <div className='dotAndText'><Icon className="yellowDot dot" icon="carbon:dot-mark" /><p>En cours</p></div>}</td>
                <td className='noBorderBottom' style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}><ReleveHeureDotMenu currentDay={props.dateJour} supprimerTouteHoraires={props.supprimerTouteHoraires} supprimerHeuresChantier={props.supprimerHeuresChantier} copierHoraireVers={props.copierHoraireVers} statutSemaine={props.statutSemaine} allDays={props.allDays} chantiers={jourIndex !== -1 ? joursState[jourIndex].chantier : null} /><div onClick={(e) => openJourAction(e)} style={{ flex: 1 }} className='transparentContainer'></div></td>
            </tr>
            {jourIndex === -1 ? <ReleveHeureListAndForm supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} supprimerSalarieHeure={props.supprimerSalarieHeure} chantier={null} open={isJourOpen} jourIndex={jourIndex} jourDate={jourKeyFormated} /> :
                joursState[jourIndex].chantier.map((chantier) => {
                    return (
                        <ReleveHeureListAndForm modifierAbsenceSalarie={props.modifierAbsenceSalarie} supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} modifierHeureSalarie={props.modifierHeureSalarie} modifierChantier={props.modifierChantier} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} key={chantier._id._id + jourKeyFormated} supprimerSalarieHeure={props.supprimerSalarieHeure} open={isJourOpen} jourIndex={jourIndex} jourDate={jourKeyFormated} chantier={chantier._id} />
                    );
                })
            }
            {isChantierAjouterSupplementaire && <ReleveHeureListAndForm peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} supprimerSalarieHeure={props.supprimerSalarieHeure} chantier={null} open={isJourOpen} jourIndex={jourIndex} jourDate={jourKeyFormated} />}
            {isJourOpen ?
                <tr>
                    <td className="ajouterChantier-buttonContainer" colSpan="6">
                        <button disabled={isChantierAjouterSupplementaire || jourIndex === -1 ? true : false} className={isChantierAjouterSupplementaire || jourIndex === -1 ? "validate-button-fw disabled-button ajouterChantier-button" : "validate-button-fw ajouterChantier-button"} onClick={() => setIsChantierAjouterSupplementaire(true)} value="AddChantier">Ajouter un chantier</button>
                    </td>
                </tr>
                : null}

        </>

    );
};

export default JourReleveHeure;