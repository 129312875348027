import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SelectSearch from 'react-select-search';
import axiosInstance from '../../utils/axiosInstance';
import * as actionCreators from '../../store/actions/exportAction';
import ReleveHeureList from './ReleveHeureList';
import TimePicker from 'react-time-picker-input';
import "react-time-picker-input/dist/components/TimeInput.css";
import { formatIntTwoDigitString, getChantierByIdAndOptions } from '../../utils/GeneralHelper';
import customToast from '../../utils/ToastifyHelper';
import EditIcon from '../icon/EditIcon';
import { isWithinInterval, format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import ReactTooltip from "react-tooltip";
import { Icon } from '@iconify/react';
import Select from 'react-select';
import { showError } from '../../utils/ErrorHelper';

const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));

const ReleveHeureListAndForm = (props) => {
    const dispatch = useDispatch();
    const currentWeekState = useSelector(
        state => state.currentWeek
    );
    const chantierState = useSelector(
        state => state.chantierZoneList.allChantier
    );
    const absenceState = useSelector(state => state.absenceList);
    const statutSemaineState = useSelector(
        state => state.releveHeure.statut_horaire_semaine
    );
    const userConnectedId = useSelector(
        state => state.user.user.id_utilisateur
    );
    const userListState = useSelector(state => state.userList);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    // const [horaire, setHoraire] = useState({heure: 0, minute:0})
    const commentaire = useRef();
    // const heure = useRef();
    // const minute = useRef();
    const [horaireMatinDeb, sethoraireMatinDeb] = useState("--:--");
    const [horaireMatinFin, sethoraireMatinFin] = useState("--:--");
    const [horaireApremDeb, sethoraireApremDeb] = useState("--:--");
    const [horaireApremFin, setHoraireApremFin] = useState("--:--");
    const [totalHeure, setTotalHeure] = useState("--:--");
    const [salarieAbsent, setSalarieAbsent] = useState(null);


    // FORM SUBMIT
    const onSubmit = (isTravail) => {
        setButtonDisabled(true);
        if ((chantierSelected === null && props.jourIndex === -1)) {
            customToast.error("Aucun chantier sélectionné");
            setButtonDisabled(false);
        }
        else if (salarieSelectedId === null) {
            // alert("veuillez renseigner tout les champs")
            customToast.error("Aucun salarié sélectionné");
            setButtonDisabled(false);
        }
        else {
            const horaireMatinDebutT = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const horaireMatinFinT = horaireMatinFin.match(/^(\d+):(\d+)/);
            const horaireApremDebutT = horaireApremDeb.match(/^(\d+):(\d+)/);
            const horaireApremFinT = horaireApremFin.match(/^(\d+):(\d+)/);
            if ((horaireMatinDebutT !== null && horaireMatinFinT !== null) || (horaireApremDebutT !== null && horaireApremFinT !== null) || !isTravail) {
                let heureMatDeb = null;
                let minuteMatDeb = null;
                let heureMatFin = null;
                let minuteMatFin = null;
                let heureApremDeb = null;
                let minuteApremDeb = null;
                let heureApremFin = null;
                let minuteApremFin = null;
                if (horaireMatinDebutT !== null && horaireMatinFinT !== null) {
                    heureMatDeb = parseInt(horaireMatinDebutT[1]);
                    minuteMatDeb = parseInt(horaireMatinDebutT[2]);
                    heureMatFin = parseInt(horaireMatinFinT[1]);
                    minuteMatFin = parseInt(horaireMatinFinT[2]);
                }
                if (horaireApremDebutT !== null && horaireApremFinT !== null) {
                    heureApremDeb = parseInt(horaireApremDebutT[1]);
                    minuteApremDeb = parseInt(horaireApremDebutT[2]);
                    heureApremFin = parseInt(horaireApremFinT[1]);
                    minuteApremFin = parseInt(horaireApremFinT[2]);
                }
                if (!(heureMatDeb === null && minuteMatDeb === null && heureMatFin === null && minuteMatFin === null) && (heureMatDeb === null || minuteMatDeb === null || heureMatFin === null || minuteMatFin === null)) {
                    customToast.error("Les heures du matin sont incomplètes");
                    setButtonDisabled(false);
                }
                else if (!(heureApremDeb === null && minuteApremDeb === null && heureApremFin === null && minuteApremFin === null) && (heureApremDeb === null || minuteApremDeb === null || heureApremFin === null || minuteApremFin === null)) {
                    customToast.error("Les heures de l'après-midi sont incomplètes");
                    setButtonDisabled(false);
                }
                else if (heureMatDeb === null && minuteMatDeb === null && heureMatFin === null && minuteMatFin === null && heureApremDeb === null && minuteApremDeb === null && heureApremFin === null && minuteApremFin === null && isTravail) {
                    customToast.error("Aucune horaires saisies");
                    setButtonDisabled(false);
                }
                else {
                    // navigation.goBack();
                    let error = false;
                    if (heureMatDeb !== null) {
                        const totalMinuteMatinDebut = heureMatDeb * 60 + minuteMatDeb;
                        const totalMinuteMatinFin = heureMatFin * 60 + minuteMatFin;
                        if (totalMinuteMatinDebut >= totalMinuteMatinFin) {
                            error = true;
                            customToast.error("L'horaire saisie est incorrect, l'heure de fin, ne peut pas être antérieur à l'heure de début");
                            setButtonDisabled(false);
                        }
                    }
                    if (heureApremDeb !== null) {
                        const totalMinuteApremDebut = heureApremDeb * 60 + minuteApremDeb;
                        const totalMinuteApremFin = heureApremFin * 60 + minuteApremFin;
                        if (totalMinuteApremDebut >= totalMinuteApremFin) {
                            error = true;
                            customToast.error("L'horaire saisie est incorrect, l'heure de fin, ne peut pas être antérieur à l'heure de début");
                            setButtonDisabled(false);
                        }
                    }
                    if (!error) {
                        if (isTravail) {
                            let postValueTravaux = {
                                'date_jour': props.jourDate,
                                'commentaire': commentaire.current.value,
                                'heureMatDeb': heureMatDeb,
                                'minuteMatDeb': minuteMatDeb,
                                'heureMatFin': heureMatFin,
                                'minuteMatFin': minuteMatFin,
                                'heureApremDeb': heureApremDeb,
                                'minuteApremDeb': minuteApremDeb,
                                'heureApremFin': heureApremFin,
                                'minuteApremFin': minuteApremFin,
                            };
                            if (props.chantier === null) {
                                postValueTravaux['chantier'] = chantierSelected.value;
                            }
                            else {
                                postValueTravaux['chantier'] = props.chantier._id;
                            }
                            let postUrl = "";
                            let listName = "";
                            let addListName = "";
                            switch (salarieSelectedStatut) {
                                case "Ouvrier":
                                    postUrl = "/travails_ouvriers";
                                    listName = "travail_ouvriers";
                                    addListName = "add_travail_ouvriers";
                                    postValueTravaux['id_ouvrier'] = salarieSelectedId;
                                    break;
                                case "Intérimaire":
                                    postUrl = "/travails_interimaires";
                                    listName = "travail_interimaires";
                                    addListName = "add_travail_interimaires";
                                    postValueTravaux['id_interimaire'] = salarieSelectedId;
                                    break;
                                case "Chef de chantier":
                                    postUrl = "/diriger_chantiers_classique";
                                    listName = "travail_chefs_chantier";
                                    addListName = "add_travail_chefs_chantier";
                                    postValueTravaux['id_chef_chantier'] = salarieSelectedId;
                                    break;
                                default:
                                    break;
                            }
                            // pas de index ajouter jour sinon modifier
                            if (statutSemaineState === null) {
                                axiosInstance.post("/horaires_semaines/me", { numero_semaine: currentWeekState.weekNumber, annee: currentWeekState.year.yearNumber });
                            }
                            axiosInstance.post(postUrl, postValueTravaux)
                                .then((result) => {
                                    if (props.jourIndex === -1) {
                                        const postReleve = { date_jour: props.jourDate, [listName]: [result.data._id], chantier: chantierSelected.value };
                                        axiosInstance.patch("/horaires_semaines/ajouter_jour/me&" + currentWeekState.year.yearNumber + "&" + currentWeekState.weekNumber, postReleve)
                                            .then((res) => {
                                                props.peutAjouterChantier();
                                                customToast.success("L'heure a été ajoutée avec succès");
                                                dispatch(loadHoraireOnChange(currentWeekState.year.yearNumber, currentWeekState.weekNumber));
                                            }).catch((error) => {
                                                setButtonDisabled(false);
                                                showError(error);
                                            });
                                    }
                                    else {
                                        const postReleve = { date_jour: props.jourDate, [addListName]: [result.data._id] };
                                        if (chantierSelected !== null && chantierSelected.value !== props.chantier) {
                                            postReleve['chantier'] = chantierSelected.value;
                                        }
                                        axiosInstance.patch(`/horaires_semaines/modifier_jour/me&${currentWeekState.year.yearNumber}&${currentWeekState.weekNumber}`, postReleve)
                                            .then((res) => {
                                                if (props.chantier === null) {
                                                    props.peutAjouterChantier();
                                                }
                                                customToast.success("L'heure a été ajoutée avec succès");
                                                dispatch(loadHoraireOnChange(currentWeekState.year.yearNumber, currentWeekState.weekNumber));
                                            }).catch((error) => {
                                                setButtonDisabled(false);
                                                showError(error);
                                            });
                                    }
                                }).catch((err) => {
                                    setButtonDisabled(false);
                                    showError(err);
                                });
                        }
                        else {
                            let postValueAbsence = {
                                "utilisateur_absent": salarieSelectedId,
                                "date_deb_absence": props.jourDate,
                                "date_fin_absence": props.jourDate,
                                'commentaire': commentaire.current.value,
                            };
                            if (props.chantier === null) {
                                postValueAbsence['chantier'] = chantierSelected.value;
                            }
                            else {
                                postValueAbsence['chantier'] = props.chantier._id;
                            }
                            if (statutSemaineState === null) {
                                axiosInstance.post("/horaires_semaines/me", { numero_semaine: currentWeekState.weekNumber, annee: currentWeekState.year.yearNumber });
                            }
                            axiosInstance.post("/absences/releveAbsence", postValueAbsence)
                                .then((resultAbsence) => {
                                    if (props.jourIndex === -1) {
                                        const postAbsenceToReleve = { "date_jour": props.jourDate, chantier: postValueAbsence.chantier, absences: [resultAbsence.data._id] };
                                        axiosInstance.patch("/horaires_semaines/ajouter_jour/me&" + currentWeekState.year.yearNumber + "&" + currentWeekState.weekNumber, postAbsenceToReleve)
                                            .then((resReleve) => {
                                                props.peutAjouterChantier();
                                                customToast.success("L'absence a été ajoutée avec succès");
                                                dispatch(loadHoraireOnChange(currentWeekState.year.yearNumber, currentWeekState.weekNumber));
                                            }).catch((error) => {
                                                setButtonDisabled(false);
                                                showError(error);
                                            });
                                    }
                                    else {
                                        const postAbsenceToReleve = { "date_jour": props.jourDate, chantier: postValueAbsence.chantier, add_absences: [resultAbsence.data._id] };
                                        axiosInstance.patch(`/horaires_semaines/modifier_jour/me&${currentWeekState.year.yearNumber}&${currentWeekState.weekNumber}`, postAbsenceToReleve)
                                            .then((res) => {
                                                if (props.chantier === null) {
                                                    props.peutAjouterChantier();
                                                }
                                                customToast.success("L'absence a été ajoutée avec succès");
                                                dispatch(loadHoraireOnChange(currentWeekState.year.yearNumber, currentWeekState.weekNumber));
                                            }).catch((error) => {
                                                setButtonDisabled(false);
                                                showError(error);
                                            });
                                    }
                                }).catch((errAbsence) => {
                                    setButtonDisabled(false);
                                    showError(errAbsence);
                                });
                        }
                        // // chantier avec id different ajouter le champ chantier
                        setButtonDisabled(false);
                        setSalarieSelectedId(null);
                        setSalarieAbsent(null);
                        // sethoraireMatinDeb("--:--")
                        // sethoraireMatinFin("--:--")
                        // sethoraireApremDeb("--:--")
                        // setHoraireApremFin("--:--")
                        // setTotalHeure("--:--")
                        commentaire.current.value = "";
                    }
                }
            }
            else {
                customToast.error("Veuillez compléter les heures du salarié");
                setButtonDisabled(false);
            }
        }

    };

    // SEARCH CHANTIER

    const [chantierSelectedId, setChantierSelectedId] = useState(null);

    const searchChantierInput = useRef(null);
    const searchSalarieInput = useRef();



    const handleFilterChantier = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return optionsChantier;
            }
            const newItems = items.filter((item) => {
                return (item.searchCode.toLowerCase().includes(searchValue.toLowerCase()) || item.searchNom.toLowerCase().includes(searchValue.toLowerCase()));
            });
            return newItems;
        };
    };

    const handleChangeChantier = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE:");
        // setFormErrorSalarie(false)
        setChantierSelectedId(args[0]);
    };

    const optionsChantier = chantierState.map((chantier) => {
        let nomEtInfoChantier = `${chantier.codeChantier} - ${chantier._id.libelle_lieu} (${chantier.zone.libelle_zone})`;
        if (!chantier.statut_chantier) {
            return { name: nomEtInfoChantier, value: chantier._id._id, searchCode: chantier.codeChantier, searchNom: chantier._id.libelle_lieu };
        }
        return null;
    });

    // SEARCH SALARIE
    const [salarieSelectedId, setSalarieSelectedId] = useState(props.jourIndex === -1 || props.chantier === null ? userConnectedId : null);
    const [salarieSelectedStatut, setSalarieSelectedStatut] = useState(props.jourIndex === -1 || props.chantier === null ? 'Chef de chantier' : null);

    const handleChangeSalarie = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE:");
        setSalarieSelectedId(args[0]);
        setSalarieSelectedStatut(args[1].groupName);
        checkSalarieAbsentAndSetError(args[0]);
    };

    const currentDay = new Date(props.jourDate);

    const checkSalarieAbsentAndSetError = (idSalarie) => {
        setSalarieAbsent(null);
        for (const absence of absenceState.absences) {
            if (absence.utilisateur_absent._id === idSalarie) {
                const date_deb_absence = new Date(absence.date_deb_absence);
                const date_fin_absence = new Date(absence.date_fin_absence);
                if (isWithinInterval(currentDay, { start: date_deb_absence, end: date_fin_absence })) {
                    setSalarieAbsent("Attention, " + absence.utilisateur_absent.nom + " " + absence.utilisateur_absent.prenom + " est absent du " + format(date_deb_absence, 'eeee dd', { locale: fr }) + " au " + format(date_fin_absence, 'dd MMMM yyyy', { locale: fr }));
                    break;
                }
            }
        }
    };

    const handleFilterSalarie = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return optionsSalarie;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    // faire absence en prenant state absence que de la semaine
    const ouvrierOptions = userListState.ouvrierList.map((ouvrier) => {
        let fullName = ouvrier._id.login_matricule + " / " + ouvrier._id.nom + " " + ouvrier._id.prenom;
        return { name: fullName, value: ouvrier._id._id };
    });
    const chefChantierOptions = userListState.chefChantierList.map((chef) => {
        let fullName = chef._id.login_matricule + " / " + chef._id.nom + " " + chef._id.prenom;
        return { name: fullName, value: chef._id._id };
    });
    const interimaireOptions = userListState.interimaireList.map((interimaire) => {
        let fullName = interimaire._id.login_matricule + " / " + interimaire._id.nom + " " + interimaire._id.prenom;
        return { name: fullName, value: interimaire._id._id };
    });

    const optionsSalarie = [
        {
            type: 'group',
            name: "Ouvrier",
            items: ouvrierOptions
        },
        {
            type: 'group',
            name: "Chef de chantier",
            items: chefChantierOptions
        },
        {
            type: 'group',
            name: "Intérimaire",
            items: interimaireOptions
        }
    ];

    // const changeHeure = (e, change) => {
    //     let newValue = parseInt(e.target.value);
    //     if (newValue < 0 ) {
    //         newValue = 0;
    //     }
    //     if (newValue > 99) {
    //         newValue = 99
    //     }
    //     newValue = newValue.toString();
    //     if (newValue.length === 1) {
    //         e.target.value = "0" + newValue;
    //     }
    //     else {
    //         e.target.value = newValue;
    //     }
    //     if(change === "heure") {
    //         setHoraire({...horaire, heure: parseInt(e.target.value)})
    //     }
    //     else {
    //         setHoraire({...horaire, minute: parseInt(e.target.value)})
    //     }
    // }

    useEffect(() => {
        const tryUpdateTotalHeure = () => {
            const horaireMatinDebutT = horaireMatinDeb.match(/^(\d+):(\d+)/);
            const horaireMatinFinT = horaireMatinFin.match(/^(\d+):(\d+)/);
            const horaireApremDebutT = horaireApremDeb.match(/^(\d+):(\d+)/);
            const horaireApremFinT = horaireApremFin.match(/^(\d+):(\d+)/);
            if (horaireMatinDebutT !== null && horaireMatinFinT !== null && horaireApremDebutT !== null && horaireApremFinT !== null) {
                let totalHeureMatin = Math.abs(parseInt(horaireMatinFinT[1]) - parseInt(horaireMatinDebutT[1]));
                let totalMinuteMatin = parseInt(horaireMatinFinT[2]) - parseInt(horaireMatinDebutT[2]);
                let totalHeureAprem = Math.abs(parseInt(horaireApremFinT[1]) - parseInt(horaireApremDebutT[1]));
                let totalMinuteAprem = parseInt(horaireApremFinT[2]) - parseInt(horaireApremDebutT[2]);
                let totalHeure = totalHeureMatin + totalHeureAprem;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin + totalMinuteAprem;
                const quotientMinute = Math.floor(totalMinute / 60);
                if (quotientMinute >= 1) {
                    totalHeure += quotientMinute;
                    totalMinute = Math.floor(totalMinute % 60);
                }

                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (horaireMatinDebutT !== null && horaireMatinFinT !== null && horaireApremDebutT === null && horaireApremFinT === null) {
                let totalHeureMatin = Math.abs(parseInt(horaireMatinFinT[1]) - parseInt(horaireMatinDebutT[1]));
                let totalMinuteMatin = parseInt(horaireMatinFinT[2]) - parseInt(horaireMatinDebutT[2]);
                let totalHeure = totalHeureMatin;
                if (totalMinuteMatin < 0) {
                    totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                    totalHeure--;
                }
                let totalMinute = totalMinuteMatin;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else if (horaireMatinDebutT === null && horaireMatinFinT === null && horaireApremDebutT !== null && horaireApremFinT !== null) {
                let totalHeureAprem = Math.abs(parseInt(horaireApremFinT[1]) - parseInt(horaireApremDebutT[1]));
                let totalMinuteAprem = parseInt(horaireApremFinT[2]) - parseInt(horaireApremDebutT[2]);
                let totalHeure = totalHeureAprem;
                if (totalMinuteAprem < 0) {
                    totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                    totalHeure--;
                }
                let totalMinute = totalMinuteAprem;
                setTotalHeure(formatIntTwoDigitString(totalHeure) + ":" + formatIntTwoDigitString(totalMinute));
            }
            else {
                setTotalHeure('--:--');
            }
        };
        tryUpdateTotalHeure();
    }, [horaireMatinDeb, horaireMatinFin, horaireApremDeb, horaireApremFin]);

    const allChantierOptions = useSelector(state => state.chantierZoneList.chantierFormatOptions);
    const [chantierSelected, setChantierSelected] = useState(null);

    const divJour = <div className='jourReleveHeure-form'>
        <div className='jourReleveHeure-form-top'>
            <div className="chantierSelection">
                <label className='default-label' htmlFor="chantier">{props.jourIndex === -1 || props.chantier === null ? "Sélectionner un chantier" : "Chantier sélectionné"}</label>
                {props.jourIndex === -1 || props.chantier === null ?
                    <Select
                        options={allChantierOptions}
                        name="selectChantier"
                        className="formSelect-search-container mgB-s2"
                        classNamePrefix="formSelect-search"
                        onChange={(opt) => setChantierSelected(opt)}
                        placeholder="Sélectionner le chantier"
                        isSearchable={true}
                    />
                    // <SelectSearch
                    //         ref={searchChantierInput}
                    //         options={optionsChantier}
                    //         filterOptions={handleFilterChantier}
                    //         value=''
                    //         name="chantier"
                    //         placeholder="Rechercher un chantier"
                    //         search
                    //         onChange={handleChangeChantier}
                    // /> 
                    : <div className="chantierInputEdit-container">
                        <input readOnly type="text" className='new-default-input chantierInput-selected' disabled name="chantier" value={allChantierOptions.find(opt => opt.value === props.chantier._id) !== undefined ? allChantierOptions.find(opt => opt.value === props.chantier._id).label : ""} />
                        <button onClick={() => props.modifierChantier({ oldChantierId: props.chantier._id, date_jour: props.jourDate, optionsChantier: allChantierOptions })} className='table-modify-button'><EditIcon /></button>
                    </div>}
            </div>
            <div className='ajouterHeureSalarie-form'>
                <div className="selectSalarie">
                    <label className='default-label' htmlFor="salarieSelect">Sélectionner un salarié</label>
                    <SelectSearch
                        ref={searchSalarieInput}
                        options={optionsSalarie}
                        filterOptions={handleFilterSalarie}
                        value={salarieSelectedId === null ? '' : salarieSelectedId}
                        name="salarie"
                        placeholder="Choisir un salarié"
                        search
                        onChange={handleChangeSalarie}
                    />
                    {salarieAbsent !== null && <p className="login-error-message">{salarieAbsent}</p>}
                </div>
                <div className="horaireContainer">
                    <label className='default-label' htmlFor="select-heure-matinFin">Matin</label>
                    <div className="heureMatin">
                        <TimePicker name='select-horaire-matinDeb' value={horaireMatinDeb} allowDelete onChange={(dateString) => sethoraireMatinDeb(dateString)} />
                        <p>-</p>
                        <TimePicker name='select-horaire-matinFin' value={horaireMatinFin} allowDelete onChange={(dateString) => sethoraireMatinFin(dateString)} />
                    </div>
                </div>
                <div className="horaireContainer">
                    <label className='default-label' htmlFor="select-heure-matinFin">Après-midi</label>
                    <div className="heureAprem">
                        <TimePicker name='select-horaire-apremDeb' value={horaireApremDeb} allowDelete onChange={(dateString) => sethoraireApremDeb(dateString)} />
                        <p>-</p>
                        <TimePicker name='select-horaire-apremFin' value={horaireApremFin} allowDelete onChange={(dateString) => setHoraireApremFin(dateString)} />
                    </div>
                </div>
                <div className="totalContainer">
                    <label className='default-label' htmlFor="select-heure-Total">Total</label>
                    <div className="totalHeure">
                        <div className="heureInputAndLabelContainer">
                            <TimePicker name='select-horaire-Total' value={totalHeure} disabled />
                        </div>
                    </div>
                </div>
                <div className="commentaireContainer">
                    <div className="commentaireOnly">
                        <label className='default-label' htmlFor="commentaire">Commentaire</label>
                        <input ref={commentaire} type="text" className='default-input' placeholder="Commentaire" />
                    </div>
                    <div className="ajouterHoraireButton-container">
                        <button disabled={statutSemaineState === 0 || statutSemaineState === null ? isButtonDisabled : true} className="ajouterHeureButton" onClick={() => onSubmit(true)} value="Ajouter"><Icon icon="entypo:plus" /></button>
                        <button className="ajouterAbsenceButton" disabled={statutSemaineState === 0 || statutSemaineState === null ? isButtonDisabled : true} onClick={() => onSubmit(false)} value="AjouterA"><Icon icon="fa:user-times" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>;

    return (
        <>
            {props.open ?
                <tr className='jourTableSubRow'>
                    <td className="salarieHeure-td" colSpan="6">
                        <div className="salarieHeureAndForm-container">{divJour}
                            {props.chantier !== null && <ReleveHeureList searchFilter={handleFilterSalarie} optionsSalarie={optionsSalarie} modifierAbsenceSalarie={props.modifierAbsenceSalarie} supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} modifierHeureSalarie={props.modifierHeureSalarie} chantier={props.chantier} supprimerSalarieHeure={props.supprimerSalarieHeure} jourIndex={props.jourIndex} />}
                        </div>
                    </td>
                </tr>
                :
                null
            }
        </>
    );
};

export default ReleveHeureListAndForm;