import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Hider from "react-hider";
import axiosInstance from '../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../utils/ErrorHelper';
import LoadingBar from 'react-top-loading-bar';
import SearchInputApi from '../../components/ui/SearchInputApi';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { getSecteursActivite } from '../../utils/GeneralHelper';
import PaginationTable from '../../components/tables/PaginationTable';
import NewTableRender from '../../components/tables/NewTableRender';
import { getReleveHeureChefAdminColumns, getReleveHeureSemaineAdminColumns } from '../../components/tables/TableColumns';
import * as actionCreators from "../../store/actions/exportAction";
import Modal from '../../components/Modal';
import ListeSalarieReleveHeure from '../../components/NoActionModals/ListeSalarieReleveHeure';
import fileDownload from 'js-file-download';
import InsideNavigationBar from '../../components/ui/InsideNavigationBar';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ClipLoader from "react-spinners/ClipLoader";
import customToast from '../../utils/ToastifyHelper';
import { format } from 'date-fns';

const saveTableOptions = (tableName, tableOptions) => (actionCreators.saveTableOptions(tableName, tableOptions));
const savePageOptions = (pageName, pageOptions) => (actionCreators.savePageOptions(pageName, pageOptions));


const ReleveHeureAdmin = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageOptions = useSelector(
        state => state.pageState
    );
    const [afficherRelevePar, setAfficherRelevePar] = useState(pageOptions.hasOwnProperty("ReleveHeureAdmin") && pageOptions["ReleveHeureAdmin"].hasOwnProperty("afficherRelevePar") ? pageOptions["ReleveHeureAdmin"]["afficherRelevePar"] : "Chef");
    const [releveParChef, setReleveParChef] = useState({ chefEtNombreReleve: [], limit: null, page: null, total: 0, sortProperty: { sort: "nom", order: "asc" } });
    const [releveParSemaine, setReleveParSemaine] = useState({ horaire_semaines: [], limit: null, page: null, total: 0, sortProperty: { sort: "numero_semaine", order: "desc" } });
    const [searchBarContent, setSearchBarContent] = useState(pageOptions.hasOwnProperty("ReleveHeureAdmin") && pageOptions["ReleveHeureAdmin"].hasOwnProperty("searchBarContent") ? pageOptions["ReleveHeureAdmin"]["searchBarContent"] : "");
    const [chefChantierOptions, setChefChantierOptions] = useState([]);
    const [exportChefSelected, setExportChefSelected] = useState(null);
    const [numeroSemaine, setNumeroSemaine] = useState([]);
    const [numeroSelected, setNumeroSelected] = useState(null);
    const [dateExport, onChange] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)]);
    const [checkedGo, setCheckedGo] = useState(true);
    const [checkedTp, setCheckedTp] = useState(true);
    const [checkedInterimaire, setCheckedInterimaire] = useState(true);
    let [loading, setLoading] = useState(false);
    let [color,] = useState("#dcd8d3");
    const onChangeSearch = (e) => {
        setSearchBarContent(e.target.value);
    };
    const [secteurAffiche, setSecteurAffiche] = useState(null);
    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const onChangeChef = (opt) => {
        setExportChefSelected(opt);
        if (opt !== null) {
            setLoadingApi(true);
            progressBarRef.current.continuousStart();
            axiosInstance.get(`/chefs_chantier/chef_chantiers_numero_semaine/${opt._id}`)
                .then((result) => {
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                    setNumeroSemaine(result.data);
                }).catch((err) => {
                    showError(err);
                });
        }
    };




    const onExportReleveParSemaine = () => {
        if (exportChefSelected !== null && numeroSelected !== null) {
            setLoadingApi(true);
            progressBarRef.current.continuousStart();
            axiosInstance.get(`/export_excel/releve_heure_semaine_chef/${exportChefSelected._id}&${numeroSelected.annee}&${numeroSelected.numero_semaine}`, {
                responseType: 'blob',
            })
                .then((result) => {
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                    fileDownload(result.data, `exportSemaine ${numeroSelected.numero_semaine} année ${numeroSelected.annee} de ${exportChefSelected.nom} ${exportChefSelected.prenom}.xlsx`);
                }).catch((err) => {
                    showError(err);
                });
        }
        else {
            customToast.error("Veuillez sélectionner un chef de chantier et un numéro de semaine pour exporter la semaine");
        }
    };

    // api progress bar
    const [loadingApi, setLoadingApi] = useState(false);
    const progressBarRef = useRef(null);

    const pageName = "ReleveHeureAdmin";
    const tableOptions = useSelector((state) => state.tableOptions);

    const loadChefsChantiers = () => {
        if (!chefChantierOptions.length) {
            setLoadingApi(true);
            progressBarRef.current.continuousStart();
            axiosInstance.get("/chefs_chantier/chef_chantiers_options")
                .then((result) => {
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                    setChefChantierOptions(result.data);
                }).catch((err) => {
                    showError(err);
                });
        }
    };

    useEffect(() => {
        loadChefsChantiers();
    }, []);

    // gestion salarié présent
    const [salariesSelected, setSalariesSelected] = useState(null);

    useEffect(() => {
        if (salariesSelected !== null) {
            fetchData(1, releveParSemaine.limit);
        }
    }, [salariesSelected]);



    const handleSalarieSelectedChange = salarieSelected => {
        setSalariesSelected(salarieSelected || []);
    };

    const getSalarieData = async (inputText, callback) => {
        try {
            const response = await axiosInstance.get(`/utilisateurs/utilisateursOptions?search=${inputText}`);
            callback(response.data.map(i => ({ label: `${i.nom} ${i.prenom}`, value: i._id, userRole: i.userRole.statut })));
        } catch (error) {
            showError(error);
        }
    };

    const fetchData = (page, limit, sort = "") => {
        let fetchUrl;
        if (afficherRelevePar === "Chef") {
            fetchUrl = "/chefs_chantier/nombre_releve?";
            if (page !== null) {
                fetchUrl += `page=${page}&`;
            }
            else {
                if (tableOptions.hasOwnProperty("ReleveHeureParChef")) {
                    fetchUrl += `page=${tableOptions["ReleveHeureParChef"].page}&`;
                }
            }
            if (limit !== null) {
                fetchUrl += `limit=${limit}&`;
            }
            else {
                if (tableOptions.hasOwnProperty("ReleveHeureParChef")) {
                    fetchUrl += `limit=${tableOptions["ReleveHeureParChef"].limit}&`;
                }
            }
            if (secteurAffiche !== null) {
                fetchUrl += `secteur=${secteurAffiche}&`;
            }
            else {
                if (tableOptions.hasOwnProperty("ReleveHeureParChef")) {
                    if (tableOptions["ReleveHeureParChef"].secteurs.length === 1) {
                        setSecteurAffiche(tableOptions["ReleveHeureParChef"].secteurs[0]);
                        fetchUrl += `secteur=${tableOptions["ReleveHeureParChef"].secteurs[0]}&`;
                    }
                }
            }
            if (searchBarContent === null) {
                if (tableOptions.hasOwnProperty("ReleveHeureParChef") && tableOptions["ReleveHeureParChef"].search && tableOptions["ReleveHeureParChef"].search !== "") {
                    setSearchBarContent(tableOptions["ReleveHeureParChef"].search);
                    fetchUrl += `search=${tableOptions["ReleveHeureParChef"].search}&`;
                }
            }
            else {
                if (searchBarContent !== "") {
                    fetchUrl += `search=${searchBarContent}&`;
                }
            }
            if (sort !== "") {
                fetchUrl += `sort=${sort}`;
            }
            else {
                fetchUrl += `sort=${releveParChef.sortProperty.sort},${releveParChef.sortProperty.order}`;
            }
            setLoadingApi(true);
            progressBarRef.current.continuousStart();
            axiosInstance.get(fetchUrl)
                .then((result) => {
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                    setReleveParChef(result.data);
                    //save page and filter to redux
                    dispatch(saveTableOptions("ReleveHeureParChef", { limit: result.data.limit, page: result.data.page, secteurs: result.data.secteurs, sortProperty: result.data.sortProperty, search: result.data.search }));
                }).catch((err) => {
                    showError(err);
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                });
        }
        else if (afficherRelevePar === "Releve") {
            let idSalarieIn = salariesSelected ? salariesSelected.map((item) => {
                return { _id: item.value, userRole: item.userRole };
            }) : [];

            fetchUrl = "/horaires_semaines?";
            // PAGE
            if (page !== null) {
                fetchUrl += `page=${page}&`;
            }
            else {
                if (tableOptions.hasOwnProperty("ReleveHeureParSemaine")) {
                    fetchUrl += `page=${tableOptions["ReleveHeureParSemaine"].page}&`;
                }
            }
            // LIMIT
            if (limit !== null) {
                fetchUrl += `limit=${limit}&`;
            }
            else {
                if (tableOptions.hasOwnProperty("ReleveHeureParSemaine")) {
                    fetchUrl += `limit=${tableOptions["ReleveHeureParSemaine"].limit}&`;
                }
            }
            // SEARCH
            if (searchBarContent === null) {
                if (tableOptions.hasOwnProperty("ReleveHeureParSemaine") && tableOptions["ReleveHeureParSemaine"].search && tableOptions["ReleveHeureParSemaine"].search !== "") {
                    setSearchBarContent(tableOptions["ReleveHeureParSemaine"].search);
                    fetchUrl += `search=${tableOptions["ReleveHeureParSemaine"].search}&`;
                }
            }
            else {
                if (searchBarContent !== "") {
                    fetchUrl += `search=${searchBarContent}&`;
                }
            }
            // SORT
            if (sort !== "") {
                fetchUrl += `sort=${sort}`;
            }
            else {
                fetchUrl += `sort=${releveParSemaine.sortProperty.sort},${releveParSemaine.sortProperty.order}`;
            }
            // AXIOS
            setLoadingApi(true);
            progressBarRef.current.continuousStart();
            axiosInstance.get(fetchUrl, { params: { "searchedSalarieId": JSON.stringify(idSalarieIn) } })
                .then((result) => {
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                    setReleveParSemaine(result.data);
                    //save page and filter to redux
                    dispatch(saveTableOptions("ReleveHeureParSemaine", { limit: result.data.limit, page: result.data.page, sortProperty: result.data.sortProperty, search: result.data.search }));
                }).catch((err) => {
                    showError(err);
                    setLoadingApi(false);
                    progressBarRef.current.complete();
                });
        }
    };

    const fetchDataFromTable = (sortProperties) => {
        let sort = "";
        if (afficherRelevePar === "Chef") {
            if (sortProperties.length) {
                sort += `${sortProperties[0].id.includes(".") ? sortProperties[0].id.split(".")[1] : sortProperties[0].id},${sortProperties[0].desc ? "desc" : "asc"}`;
            }
            fetchData(releveParChef.page, releveParChef.limit, sort);
        }
        else {
            if (sortProperties.length) {
                sort += `${sortProperties[0].id},${sortProperties[0].desc ? "desc" : "asc"}`;
            }
            fetchData(releveParSemaine.page, releveParSemaine.limit, sort);
        }
    };

    const openSalariePresentSemaine = (semaineData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ListeSalarieReleveHeure semaineData={semaineData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const downloadExport = () => {
        if (dateExport !== null && dateExport[0] !== null && dateExport[1] !== null) {
            if (!checkedGo && !checkedTp && !checkedInterimaire) {
                customToast.error("Sélectionner au moins une catégorie de salarié à exporter");
            }
            else {
                setLoading(true);
                progressBarRef.current.continuousStart();
                axiosInstance.get(`/export_excel/exportHeureSalarie?dateDeb=${format(dateExport[0], "yyyy-MM-dd")}&dateFin=${format(dateExport[1], "yyyy-MM-dd")}&salarieGo=${checkedGo.toString()}&salarieTp=${checkedTp.toString()}&salarieInterimaire=${checkedInterimaire.toString()}`, {
                    responseType: 'blob',
                    timeout: 60000,
                })
                    .then((result) => {
                        let nomExportType = "";
                        if (checkedGo) {
                            nomExportType += "_GrosOeuvres";
                        }
                        if (checkedTp) {
                            nomExportType += "_TravauxPublics";
                        }
                        if (checkedInterimaire) {
                            nomExportType += "_Interimaires";
                        }
                        fileDownload(result.data, "ExportHeureSalaries" + nomExportType + " du " + format(dateExport[0], "dd/MM/yyyy") + " au " + format(dateExport[1], "dd/MM/yyyy") + ".xlsx");
                        progressBarRef.current.complete();
                        setLoading(false);
                    }).catch((err) => {
                        showError(err);
                        progressBarRef.current.complete();
                        setLoading(false);
                    });
            }
        }
        else {
            customToast.error("Sélectionner une date de début et de fin de période pour télécharger l'export");
        }
    };

    const handleChangeGo = () => {
        setCheckedGo(!checkedGo);
    };

    const handleChangeTp = () => {
        setCheckedTp(!checkedTp);
    };

    const handleChangeInterimaire = () => {
        setCheckedInterimaire(!checkedInterimaire);
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            dispatch(savePageOptions("ReleveHeureAdmin", {
                searchBarContent: searchBarContent,
                afficherRelevePar: afficherRelevePar
            }));
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchBarContent, afficherRelevePar]);

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div className="pageTitle-container transparent">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <h1>Relevé d'heures</h1>
                        <InsideNavigationBar
                            activeValue={afficherRelevePar}
                            setActiveValue={(value) => setAfficherRelevePar(value)}
                            links={[
                                { label: "Relevé par chefs de chantier", value: "Chef" },
                                { label: "Relevé par semaine", value: "Releve" },
                                { label: "Exporter des relevés d'heures", value: "Export" }
                            ]}
                        />
                        {/* <ul className='pageContent-nav' style={{height: "3.8rem"}}>
                            <li className={`btn ${afficherRelevePar === "Chef" ? "link-selected" : "link"}`} style={{display: "flex", justifyContent: "center", alignItems: "center"}} onClick={() => setAfficherRelevePar("Chef")}><p>Relevé par chefs de chantier</p></li>
                            <li className={`btn ${afficherRelevePar === "Releve" ? "link-selected" : "link"}`} style={{display: "flex", justifyContent: "center", alignItems: "center"}} onClick={() => setAfficherRelevePar("Releve")}><p>Relevé par semaine</p></li>
                            <li className={`btn ${afficherRelevePar === "Export" ? "link-selected" : "link"}`} style={{display: "flex", justifyContent: "center", alignItems: "center"}} onClick={() => {setAfficherRelevePar("Export"); loadChefsChantiers()}}><p>Exporter des relevés d'heures</p></li>
                        </ul> */}
                    </div>
                </div>
                <Hider state={afficherRelevePar === "Chef" || afficherRelevePar === "Releve"}>
                    <div className="search-filter-container mgB-s3">
                        <div className='search-filter-content'>
                            <div className="search-filter-section">
                                <p>Rechercher un relevé d'heure</p>
                                <SearchInputApi placeholder={afficherRelevePar === "Chef" ? "Rechercher un relevé par nom, prenom, matricule..." : "Rechercher un relevé par nom, prenom"} searchContent={searchBarContent ? searchBarContent : ""} onChangeSearch={onChangeSearch} search={() => fetchData(1, releveParChef.limit)} />
                            </div>
                            <Hider state={afficherRelevePar === "Chef"}>
                                <div className="search-filter-section">
                                    <p>Secteur</p>
                                    <select onChange={(e) => setSecteurAffiche(e.target.value)} className='new-default-input' value={secteurAffiche ? secteurAffiche : "All"}>
                                        <option value="All">Tout les secteurs</option>
                                        {getSecteursActivite().map((secteur) => {
                                            return <option key={secteur} value={secteur}>{secteur}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="search-filter-section">
                                    <p>Salarié présent</p>
                                    <AsyncSelect
                                        isMulti
                                        value={salariesSelected}
                                        onChange={handleSalarieSelectedChange}
                                        placeholder={"Rechercher un salarié dans la semaine"}
                                        loadOptions={getSalarieData}
                                        cacheOptions={true}
                                        noOptionsMessage={() => 'Aucun salarié ne correspond a votre recherche'}
                                    />
                                </div>
                            </Hider>
                        </div>
                    </div>
                </Hider>
                <Hider state={afficherRelevePar === "Chef"}>
                    <>
                        <NewTableRender
                            columns={getReleveHeureChefAdminColumns(navigate)}
                            tableData={releveParChef.chefEtNombreReleve}
                            // fetchData={(page, limit, sortByProperty, sortByOrder) => fetchData(page, limit, sortByProperty.split(".")[1], sortByOrder)}
                            fetchData={(sortProperties) => fetchDataFromTable(sortProperties)}
                            initialSort={tableOptions.hasOwnProperty("ReleveHeureParChef") ?
                                tableOptions["ReleveHeureParChef"].sortProperty.sort === "nbReleve" || tableOptions["ReleveHeureParChef"].sortProperty.sort === "nbReleveEnCours" ?
                                    [{ id: tableOptions["ReleveHeureParChef"].sortProperty.sort, desc: tableOptions["ReleveHeureParChef"].sortProperty.order === "desc" }] :
                                    [{ id: `chef.${tableOptions["ReleveHeureParChef"].sortProperty.sort}`, desc: tableOptions["ReleveHeureParChef"].sortProperty.order === "desc" }]
                                : [{ id: "chef.nom", desc: false }]
                            }
                        />
                        <PaginationTable fetchData={fetchData} page={releveParChef.page} limit={releveParChef.limit} total={releveParChef.total} disabled={loadingApi} />
                    </>
                </Hider>
                <Hider state={afficherRelevePar === "Releve"}>
                    <>
                        <NewTableRender
                            columns={getReleveHeureSemaineAdminColumns(navigate, (semaineData) => openSalariePresentSemaine(semaineData))}
                            tableData={releveParSemaine.horaire_semaines}
                            fetchData={(sortProperties) => fetchDataFromTable(sortProperties)}
                            initialSort={tableOptions.hasOwnProperty("ReleveHeureParSemaine") ? [{ id: tableOptions["ReleveHeureParSemaine"].sortProperty.sort, desc: tableOptions["ReleveHeureParSemaine"].sortProperty.order === "desc" }] : [{ id: "numero_semaine", desc: false }]}
                        />
                        <PaginationTable fetchData={fetchData} page={releveParSemaine.page} limit={releveParSemaine.limit} total={releveParSemaine.total} disabled={loadingApi} />
                    </>
                </Hider>
                <Hider state={afficherRelevePar === "Export"}>
                    <>
                        <div className="exportSection mgB-m2">
                            <div className="excelCard">
                                <div className="imgContainer">
                                    {/* <img src="https://www.distanciel.estc.fr/wp-content/uploads/2020/10/microsoft-excel-logo-1.png" alt="excelImage"/> */}
                                </div>
                                <div className="downloadOptions">
                                    <h3 className="modal-title">Exporter les heures des salariés</h3>
                                    <div className="downloadLine">
                                        <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner la période d'export: </label>
                                        <DateRangePicker calendarIcon={null} minDate={new Date("2022-01-01")} maxDate={new Date("2099-12-31")} className="input-datetimePicker searchDate-select" showLeadingZeros={true} format="dd/MM/yyyy" onChange={onChange} value={dateExport} />
                                    </div>
                                    <div className="downloadLine">
                                        <label className='ajouter-form-label default-label' htmlFor="nom">Sélectionner les sociétés des salariés: </label>
                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedGo}
                                                    onChange={handleChangeGo}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Gros oeuvres</label>
                                            </div>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedTp}
                                                    onChange={handleChangeTp}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Travaux publics</label>
                                            </div>
                                            <div className="checkboxExport">
                                                <input className='login-checkbox'
                                                    type="checkbox"
                                                    checked={checkedInterimaire}
                                                    onChange={handleChangeInterimaire}
                                                />
                                                <label className='checkbox-label' htmlFor="checkbox">Intérimaires</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => downloadExport()} className='btn btn-primary downloadExportButton mgT-m1'>Télécharger</button>
                                </div>
                            </div>
                        </div>
                        <div className="container-basic">
                            <h3 className='mgB-s2'>Exporter une semaine</h3>
                            <p className='form-label'>Chef de chantier</p>
                            <Select
                                options={chefChantierOptions}
                                className="formSelect-search-container formSelect-search-container-full mgB-s2"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeChef(opt)}
                                placeholder="Sélectionner un chef de chantier"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                value={exportChefSelected || ''}
                                getOptionLabel={e => `(${e.login_matricule}) - ${e.nom} ${e.prenom}`}
                                getOptionValue={e => e._id}
                            />
                            <p className='form-label'>Numéro de la semaine</p>
                            <Select
                                options={numeroSemaine}
                                className="formSelect-search-container formSelect-search-container-full mgB-m1"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => setNumeroSelected(opt)}
                                placeholder="Sélectionner le numéro de semaine"
                                isDisabled={exportChefSelected === null}
                                value={numeroSelected || ''}
                                getOptionLabel={e => `${e.annee} - Semaine ${e.numero_semaine}`}
                                getOptionValue={e => e._id}
                            />
                            <button onClick={() => onExportReleveParSemaine()} className='btn btn-primary fw'>Télécharger</button>
                        </div>
                    </>
                </Hider>
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </>
    );
};

export default ReleveHeureAdmin


