import React from 'react';
import SupprimerIcon from '../icon/SupprimerIcon';
import EditIcon from '../icon/EditIcon';
import { format } from 'date-fns';
import { formatIntTwoDigitString } from '../../utils/GeneralHelper';
import ReactTooltip from "react-tooltip";
import fr from 'date-fns/esm/locale/fr/index.js';

const ReleveHeureListAdmin = (props) => {
    // RENDER SALARIE LIST
    let ouvrierHoraireList = null;
    let interimaireHoraireList = null;
    let chefHoraireList = null;
    let absenceList = null;

    const totalHeure = (travail) => {
        const heureMatinDebut = { heure: travail.horaireMat.heureDeb, minute: travail.horaireMat.minuteDeb };
        const heureMatinFin = { heure: travail.horaireMat.heureFin, minute: travail.horaireMat.minuteFin };
        const heureApremDebut = { heure: travail.horaireAprem.heureDeb, minute: travail.horaireAprem.minuteDeb };
        const heureApremFin = { heure: travail.horaireAprem.heureFin, minute: travail.horaireAprem.minuteFin };
        if (heureMatinDebut.heure !== null && heureMatinFin.heure !== null && heureApremDebut.heure !== null && heureApremFin.heure !== null) {
            let totalHeureMatin = Math.abs(heureMatinFin.heure - heureMatinDebut.heure);
            let totalMinuteMatin = heureMatinFin.minute - heureMatinDebut.minute;
            let totalHeureAprem = Math.abs(heureApremFin.heure - heureApremDebut.heure);
            let totalMinuteAprem = heureApremFin.minute - heureApremDebut.minute;
            let totalHeure = totalHeureMatin + totalHeureAprem;
            if (totalMinuteMatin < 0) {
                totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                totalHeure--;
            }
            if (totalMinuteAprem < 0) {
                totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                totalHeure--;
            }
            let totalMinute = totalMinuteMatin + totalMinuteAprem;
            const quotientMinute = Math.floor(totalMinute / 60);
            if (quotientMinute >= 1) {
                totalHeure += quotientMinute;
                totalMinute = Math.floor(totalMinute % 60);
            }

            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else if (heureMatinDebut.heure !== null && heureMatinFin.heure !== null && heureApremDebut.heure === null && heureApremFin.heure === null) {
            let totalHeureMatin = Math.abs(heureMatinFin.heure - heureMatinDebut.heure);
            let totalMinuteMatin = heureMatinFin.minute - heureMatinDebut.minute;
            let totalHeure = totalHeureMatin;
            if (totalMinuteMatin < 0) {
                totalMinuteMatin = 60 - Math.abs(totalMinuteMatin);
                totalHeure--;
            }
            let totalMinute = totalMinuteMatin;
            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else if (heureMatinDebut.heure === null && heureMatinFin.heure === null && heureApremDebut.heure !== null && heureApremFin.heure !== null) {
            let totalHeureAprem = Math.abs(heureApremFin.heure - heureApremDebut.heure);
            let totalMinuteAprem = heureApremFin.minute - heureApremDebut.minute;
            let totalHeure = totalHeureAprem;
            if (totalMinuteAprem < 0) {
                totalMinuteAprem = 60 - Math.abs(totalMinuteAprem);
                totalHeure--;
            }
            let totalMinute = totalMinuteAprem;
            return (formatIntTwoDigitString(totalHeure) + " h " + formatIntTwoDigitString(totalMinute));
        }
        else {
            return ("");
        }
    };

    if (props.jours[props.jourIndex] !== -1 && props.jours[props.jourIndex] !== undefined) {
        ouvrierHoraireList = props.jours[props.jourIndex].travail_ouvriers.map((travail) => {
            if (travail.chantier === props.chantier._id) {
                return <li key={travail.ouvrier._id._id + props.chantier._id}>
                    <p className='readOnly-input selectSalarie-readOnly-input'>{travail.ouvrier._id.nom + " " + travail.ouvrier._id.prenom}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireMat.heureDeb === null ? "" : travail.horaireMat.heureDeb + " h " + formatIntTwoDigitString(travail.horaireMat.minuteDeb) + "  -  " + travail.horaireMat.heureFin + " h " + formatIntTwoDigitString(travail.horaireMat.minuteFin)}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireAprem.heureDeb === null ? "" : travail.horaireAprem.heureDeb + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteDeb) + "  -  " + travail.horaireAprem.heureFin + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteFin)}</p>
                    <p className='readOnly-input totalHeure-readOnly-input'>{totalHeure(travail)}</p>
                    <div className='salarieList-commentaireButton-container'>
                        <p className='readOnly-input commentaireInput-readOnly'>{travail.commentaire || ''}</p>
                        <button data-tip data-for={"modifierHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.modifierHeureSalarie({ travail: travail, optionsSalarie: props.optionsSalarie, searchFilter: props.searchFilter, salarieId: travail.ouvrier._id._id, statut: "Ouvrier" })} className='table-modify-button'><EditIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierHeureTip-" + travail._id} place="bottom" effect="solid">
                            Modifier l'heure
                        </ReactTooltip>
                        <button data-tip data-for={"supprimerHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.supprimerSalarieHeure({ postData: { date_jour: format(new Date(travail.date_jour), "yyyy-MM-dd"), del_travail_ouvriers: [travail._id] }, date_jour: format(new Date(travail.date_jour), "eeee dd MMMM yyyy", { locale: fr }), nom: travail.ouvrier._id.nom, prenom: travail.ouvrier._id.prenom })} className='table-delete-button'><SupprimerIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerHeureTip-" + travail._id} place="bottom" effect="solid">
                            Supprimer l'heure
                        </ReactTooltip>
                    </div>
                </li>;
            }
            else {
                return null;
            }
        });
        interimaireHoraireList = props.jours[props.jourIndex].travail_interimaires.map((travail) => {
            if (travail.chantier === props.chantier._id) {
                return <li key={travail.interimaire._id._id + props.chantier._id}>
                    <p className='readOnly-input selectSalarie-readOnly-input'>{travail.interimaire._id.nom + " " + travail.interimaire._id.prenom}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireMat.heureDeb === null ? "" : travail.horaireMat.heureDeb + " h " + formatIntTwoDigitString(travail.horaireMat.minuteDeb) + "  -  " + travail.horaireMat.heureFin + " h " + formatIntTwoDigitString(travail.horaireMat.minuteFin)}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireAprem.heureDeb === null ? "" : travail.horaireAprem.heureDeb + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteDeb) + "  -  " + travail.horaireAprem.heureFin + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteFin)}</p>
                    <p className='readOnly-input totalHeure-readOnly-input'>{totalHeure(travail)}</p>
                    <div className='salarieList-commentaireButton-container'>
                        <p className='readOnly-input commentaireInput-readOnly'>{travail.commentaire || ''}</p>
                        <button data-tip data-for={"modifierHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.modifierHeureSalarie({ travail: travail, optionsSalarie: props.optionsSalarie, searchFilter: props.searchFilter, salarieId: travail.interimaire._id._id, statut: "Intérimaire" })} className='table-modify-button'><EditIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierHeureTip-" + travail._id} place="bottom" effect="solid">
                            Modifier l'heure
                        </ReactTooltip>
                        <button data-tip data-for={"supprimerHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.supprimerSalarieHeure({ postData: { date_jour: format(new Date(travail.date_jour), "yyyy-MM-dd"), del_travail_interimaires: [travail._id] }, date_jour: format(new Date(travail.date_jour), "eeee dd MMMM yyyy", { locale: fr }), nom: travail.interimaire._id.nom, prenom: travail.interimaire._id.prenom })} className='table-delete-button'><SupprimerIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerHeureTip-" + travail._id} place="bottom" effect="solid">
                            Supprimer l'heure
                        </ReactTooltip>
                    </div>
                </li>;
            }
            else {
                return null;
            }
        });
        chefHoraireList = props.jours[props.jourIndex].travail_chefs_chantier.map((travail) => {
            if (travail.chantier === props.chantier._id) {
                return <li key={travail.chef_chantier._id._id + props.chantier._id}>
                    <p className='readOnly-input selectSalarie-readOnly-input'>{travail.chef_chantier._id.nom + " " + travail.chef_chantier._id.prenom}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireMat.heureDeb === null ? "" : travail.horaireMat.heureDeb + " h " + formatIntTwoDigitString(travail.horaireMat.minuteDeb) + "  -  " + travail.horaireMat.heureFin + " h " + formatIntTwoDigitString(travail.horaireMat.minuteFin)}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{travail.horaireAprem.heureDeb === null ? "" : travail.horaireAprem.heureDeb + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteDeb) + "  -  " + travail.horaireAprem.heureFin + " h " + formatIntTwoDigitString(travail.horaireAprem.minuteFin)}</p>
                    <p className='readOnly-input totalHeure-readOnly-input'>{totalHeure(travail)}</p>
                    <div className='salarieList-commentaireButton-container'>
                        <p className='readOnly-input commentaireInput-readOnly'>{travail.commentaire || ''}</p>
                        <button data-tip data-for={"modifierHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.modifierHeureSalarie({ travail: travail, optionsSalarie: props.optionsSalarie, searchFilter: props.searchFilter, salarieId: travail.chef_chantier._id._id, statut: "Chef de chantier" })} className='table-modify-button'><EditIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierHeureTip-" + travail._id} place="bottom" effect="solid">
                            Modifier l'heure
                        </ReactTooltip>
                        <button data-tip data-for={"supprimerHeureTip-" + travail._id} disabled={props.statutSemaine} onClick={() => props.supprimerSalarieHeure({ postData: { date_jour: format(new Date(travail.date_jour), "yyyy-MM-dd"), del_travail_chefs_chantier: [travail._id] }, date_jour: format(new Date(travail.date_jour), "eeee dd MMMM yyyy", { locale: fr }), nom: travail.chef_chantier._id.nom, prenom: travail.chef_chantier._id.prenom })} className='table-delete-button'><SupprimerIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerHeureTip-" + travail._id} place="bottom" effect="solid">
                            Supprimer l'heure
                        </ReactTooltip>
                    </div>
                </li>;
            }
            else {
                return null;
            }
        });
        absenceList = props.jours[props.jourIndex].absences.map((absence) => {
            if (absence.chantier === props.chantier._id) {
                return <li key={absence._id}>
                    <p className='readOnly-input selectSalarie-readOnly-input'>{absence.utilisateur_absent.nom + " " + absence.utilisateur_absent.prenom}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{absence.tempsAbsence.heure === null || absence.tempsAbsence.minute === null ? "" : absence.tempsAbsence.heure + " h " + formatIntTwoDigitString(absence.tempsAbsence.minute)}</p>
                    <p className='readOnly-input selectHeure-readOnly-input'>{absence.type_absence}</p>
                    <div className='salarieList-commentaireButton-container'>
                        <p className='readOnly-input commentaireInput-readOnly'>{absence.commentaire || ''}</p>
                        <button data-tip data-for={"modifierAbsenceTip-" + absence._id} disabled={props.statutSemaine} onClick={() => props.modifierAbsenceSalarie({ absence: absence })} className='table-modify-button'><EditIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"modifierAbsenceTip-" + absence._id} place="bottom" effect="solid">
                            Modifier l'absence
                        </ReactTooltip>
                        <button data-tip data-for={"supprimerAbsenceTip-" + absence._id} disabled={props.statutSemaine} onClick={() => props.supprimerAbsenceSalarie({ postData: { date_jour: format(new Date(absence.date_deb_absence), "yyyy-MM-dd"), del_absences: [absence._id] }, date_jour: format(new Date(absence.date_deb_absence), "eeee dd MMMM yyyy", { locale: fr }), nom: absence.utilisateur_absent.nom, prenom: absence.utilisateur_absent.prenom })} className='table-delete-button'><SupprimerIcon /></button>
                        <ReactTooltip backgroundColor="#1E1E1E" offset={{ 'top': 5 }} id={"supprimerAbsenceTip-" + absence._id} place="bottom" effect="solid">
                            Supprimer l'absence
                        </ReactTooltip>
                    </div>
                </li>;
            }
            else {
                return null;
            }
        });
    }

    const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

    return (
        <ul className="salarieReleveHeure-list">
            {chefHoraireList !== null && chefHoraireList.length - countOccurrences(chefHoraireList, null) > 0 && <h3>Chef de chantier</h3>}
            {chefHoraireList}
            {ouvrierHoraireList !== null && ouvrierHoraireList.length - countOccurrences(ouvrierHoraireList, null) > 0 && <h3>Ouvrier</h3>}
            {ouvrierHoraireList}
            {interimaireHoraireList !== null && interimaireHoraireList.length - countOccurrences(interimaireHoraireList, null) > 0 && <h3>Intérimaire</h3>}
            {interimaireHoraireList}
            {absenceList !== null && absenceList.length - countOccurrences(absenceList, null) > 0 && <h3>Absences</h3>}
            {absenceList}
        </ul>
    );
};

export default ReleveHeureListAdmin;