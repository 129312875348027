import React, { useState } from 'react';
import { format } from 'date-fns';
import { dateIndexInJoursAdmin } from '../../utils/DateHelper';
import { maxByPorteeZone, truncateString } from '../../utils/GeneralHelper';
import ReleveHeureListAndFormAdmin from './ReleveHeureListAndFormAdmin';
import ReleveHeureDotMenuAdmin from '../ReleveHeureDotMenuAdmin';
import { Icon } from '@iconify/react';


const JourReleveHeureAdmin = (props) => {

    const [isJourOpen, setIsJourOpen] = useState(false);
    const [isChantierAjouterSupplementaire, setIsChantierAjouterSupplementaire] = useState(false);

    const jourKeyFormated = format(props.dateJour, "yyyy-MM-dd");
    const jourIndex = dateIndexInJoursAdmin(jourKeyFormated, props.jours);

    const chantierArrayTruncateString = (chantierArray) => {
        let truncatedLibelleChantier = [];
        for (const chantier of chantierArray) {
            truncatedLibelleChantier.push(truncateString(chantier._id.libelle_lieu, 12));
        }
        return truncatedLibelleChantier.join(', ');
    };

    const openJourAction = (event) => {
        console.log(event.currentTarget.tagName);
        if (event.currentTarget.childNodes.length === 0 || event.currentTarget.childNodes[0].tagName !== "BUTTON") {
            setIsJourOpen(!isJourOpen);
        }
    };

    const jourHorairesNull = () => {
        for (const travailOuvrier of props.jours[jourIndex].travail_ouvriers) {
            if ((Object.values(travailOuvrier.horaireAprem)).every(heure => heure === null) && (Object.values(travailOuvrier.horaireMat)).every(heure => heure === null)) {
                return true;
            }
        }
        for (const travailChefChantier of props.jours[jourIndex].travail_chefs_chantier) {
            if ((Object.values(travailChefChantier.horaireAprem)).every(heure => heure === null) && (Object.values(travailChefChantier.horaireMat)).every(heure => heure === null)) {
                return true;
            }
        }
        for (const travailInterimaire of props.jours[jourIndex].travail_interimaires) {
            if ((Object.values(travailInterimaire.horaireAprem)).every(heure => heure === null) && (Object.values(travailInterimaire.horaireMat)).every(heure => heure === null)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <tr className={isJourOpen ? "jourTableRow RowActive borderBottom" : "jourTableRow borderBottom"}>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{format(props.dateJour, "dd/MM")}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex !== -1 && props.jours[jourIndex].chantier.length ? chantierArrayTruncateString(props.jours[jourIndex].chantier) : <p>Aucun chantier</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex !== -1 && props.jours[jourIndex].chantier.length ? maxByPorteeZone(props.jours[jourIndex].chantier) : <p>Aucune zone</p>}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{jourIndex === -1 ? <p>0</p> : props.jours[jourIndex].travail_chefs_chantier.length + props.jours[jourIndex].travail_ouvriers.length + props.jours[jourIndex].travail_interimaires.length}</td>
                <td className='noBorderBottom' onClick={(e) => openJourAction(e)}>{props.statutSemaine > 0 ? <div className='dotAndText'><Icon className="greenDot dot" icon="carbon:dot-mark" /><p>Valider</p></div> : jourIndex === -1 ? <div className='dotAndText'><Icon className="redDot dot" icon="carbon:dot-mark" /><p>Aucune saisie</p></div> : jourHorairesNull() ? <div className='dotAndText'><Icon icon="gg:danger" className='danger' /><p>Certaines horaires sont incomplètes</p></div> : <div className='dotAndText'><Icon className="yellowDot dot" icon="carbon:dot-mark" /><p>En cours</p></div>}</td>
                <td className='noBorderBottom' style={{ display: "flex", alignContent: "center" }}><ReleveHeureDotMenuAdmin copierHoraireVers={props.copierHoraireVers} supprimerHeuresChantier={props.supprimerHeuresChantier} supprimerTouteHoraires={props.supprimerTouteHoraires} statutSemaine={props.statutSemaine} currentDay={props.dateJour} allDays={props.allDays} chantiers={jourIndex !== -1 ? props.jours[jourIndex].chantier : null} /><div onClick={(e) => openJourAction(e)} style={{ flex: 1 }} className='transparentContainer'></div></td>
            </tr>
            {jourIndex === -1 ? <ReleveHeureListAndFormAdmin idChef={props.idChef} modifierAbsenceSalarie={props.modifierAbsenceSalarie} numeroSemaine={props.numeroSemaine} jours={props.jours} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} supprimerSalarieHeure={props.supprimerSalarieHeure} chantier={null} open={isJourOpen} jourIndex={jourIndex} jourDate={jourKeyFormated} annee={props.annee} statutSemaine={props.statutSemaine} /> :
                props.jours[jourIndex].chantier.map((chantier) => {
                    return (
                        <ReleveHeureListAndFormAdmin idChef={props.idChef} jours={props.jours} numeroSemaine={props.numeroSemaine} modifierAbsenceSalarie={props.modifierAbsenceSalarie} supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} modifierHeureSalarie={props.modifierHeureSalarie} modifierChantier={props.modifierChantier} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} key={chantier._id._id + jourKeyFormated} annee={props.annee} statutSemaine={props.statutSemaine} supprimerSalarieHeure={props.supprimerSalarieHeure} open={isJourOpen} jourIndex={jourIndex} jourDate={jourKeyFormated} chantier={chantier._id} />
                    );
                })
            }
            {isChantierAjouterSupplementaire && <ReleveHeureListAndFormAdmin idChef={props.idChef} modifierAbsenceSalarie={props.modifierAbsenceSalarie} numeroSemaine={props.numeroSemaine} jours={props.jours} peutAjouterChantier={() => setIsChantierAjouterSupplementaire(false)} supprimerAbsenceSalarie={props.supprimerAbsenceSalarie} supprimerSalarieHeure={props.supprimerSalarieHeure} chantier={null} open={isJourOpen} jourIndex={jourIndex} annee={props.annee} statutSemaine={props.statutSemaine} jourDate={jourKeyFormated} />}
            {isJourOpen ?
                <tr>
                    <td className="ajouterChantier-buttonContainer" colSpan="6">
                        <button disabled={isChantierAjouterSupplementaire || jourIndex === -1 ? true : false} className={isChantierAjouterSupplementaire || jourIndex === -1 ? "validate-button-fw disabled-button ajouterChantier-button" : "validate-button-fw ajouterChantier-button"} onClick={() => setIsChantierAjouterSupplementaire(true)} value="AddChantier">Ajouter un chantier</button>
                    </td>
                </tr>
                : null}

        </>

    );
};

export default JourReleveHeureAdmin;