import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as actionCreators from '../../store/actions/exportAction';
import { useDispatch, useSelector } from 'react-redux';
import LiReleveHeureConsultation from './LiReleveHeureConsultation';

const onLoadPage = (dispatch, idReleve) => {
    dispatch(actionCreators.getUserList());
    dispatch(actionCreators.getAdminReleveHeure(idReleve));
    dispatch(actionCreators.getChantierZoneListReleve());
    dispatch(actionCreators.getAbsenceList());
    dispatch(actionCreators.getChantierOptions());
    // liste des absences laps de temps semaines 
};

const onQuitPage = (dispatch) => {
    dispatch(actionCreators.unloadUserList());
    dispatch(actionCreators.unloadAdminReleveHeure());
    dispatch(actionCreators.unloadChantierZoneList());
    dispatch(actionCreators.unloadAbsenceList());
};

const OneReleveHeureConsultation = () => {
    const dispatch = useDispatch();
    let { idReleve } = useParams();

    useEffect(() => {
        onLoadPage(dispatch, idReleve);
        return () => {
            onQuitPage(dispatch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idReleve]);

    const releveHeureState = useSelector(
        state => state.releveHeureAdminRdc
    );

    return (
        <>
            {releveHeureState.releveHeure.length > 0 &&
                <div className="section">
                    <div><LiReleveHeureConsultation releveHeure={releveHeureState.releveHeure[0]} /></div>
                </div>
            }
        </>
    );
};

export default OneReleveHeureConsultation





