import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { showError } from "../../utils/ErrorHelper";
import axiosInstance from "../../utils/axiosInstance";
import LoadingBar from 'react-top-loading-bar';

const RelevesHeuresConsultationV2 = () => {

    const progressBarRef = useRef(null);

    const [pageOptions, setPageOptions] = useState({
        page: 1,
        total: 0,
        totalPages: 1
    });

    let { idUtilisateur } = useParams();

    const [releveSaisiePar, setReleveSaisiePar] = useState(null);

    const [relevesHeures, setRelevesHeures] = useState([]);

    const fetchData = (page) => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/tousLesReleves/${idUtilisateur}?page=${page + 1}`).then((result) => {
            progressBarRef.current.complete();
            setRelevesHeures(relevesHeures.concat(result.data.releveHeures));
            setPageOptions({ ...pageOptions, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });
    };

    useEffect(() => {
        axiosInstance.get(`/utilisateurs/${idUtilisateur}`).then((res) => {
            setReleveSaisiePar(res.data);
        }).catch((err) => {
            showError(err);
        });
        fetchData(0);
    }, []);


    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className='section'>
                <div className="container-basic mgB-m2">
                    <h1 className="h1-blue-dark">Tous les relevés d'heures saisis par {releveSaisiePar !== null && releveSaisiePar.nom} {releveSaisiePar !== null && releveSaisiePar.prenom}</h1>
                </div>
                <ul className="releveHeureList">
                    {
                        relevesHeures.map((releveHeure) => {
                            return (<li className='container-basic mgB-m2' key={releveHeure._id}>

                            </li>);
                        })
                    }
                </ul>
            </div>
        </>
    );
};

export default RelevesHeuresConsultationV2;
