import React from 'react';
import { groupHoraireBySalarie, totalHoraireSalarie } from '../../utils/GeneralHelper';

const ListeSalarieReleveHeure = (props) => {
    const horaireSemaineTotal = groupHoraireBySalarie(props.semaineData.jours);


    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title" style={{ marginBottom: 0 }} >Liste des salariés présents dans la semaine</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <ul className="resumeSalarieHeure">
                {Object.keys(horaireSemaineTotal.chefChantier).length !== 0 && <h3>Chef de chantier</h3>}
                {Object.keys(horaireSemaineTotal.chefChantier).length !== 0 &&
                    Object.keys(horaireSemaineTotal.chefChantier).map((chefChantierId) => {
                        return <li key={chefChantierId}>{horaireSemaineTotal.chefChantier[chefChantierId].nom} {horaireSemaineTotal.chefChantier[chefChantierId].prenom} {totalHoraireSalarie(horaireSemaineTotal.chefChantier[chefChantierId].horaire)}</li>;
                    })
                }
                {Object.keys(horaireSemaineTotal.ouvrier).length !== 0 && <h3>Ouvrier</h3>}
                {Object.keys(horaireSemaineTotal.ouvrier).length !== 0 &&
                    Object.keys(horaireSemaineTotal.ouvrier).map((ouvrierId) => {
                        return <li key={ouvrierId}>{horaireSemaineTotal.ouvrier[ouvrierId].nom} {horaireSemaineTotal.ouvrier[ouvrierId].prenom} {totalHoraireSalarie(horaireSemaineTotal.ouvrier[ouvrierId].horaire)}</li>;
                    })
                }
                {Object.keys(horaireSemaineTotal.interimaire).length !== 0 && <h3>Interimaire</h3>}
                {Object.keys(horaireSemaineTotal.interimaire).length !== 0 &&
                    Object.keys(horaireSemaineTotal.interimaire).map((interimaireId) => {
                        return <li key={interimaireId}>{horaireSemaineTotal.interimaire[interimaireId].nom} {horaireSemaineTotal.interimaire[interimaireId].prenom} {totalHoraireSalarie(horaireSemaineTotal.interimaire[interimaireId].horaire)}</li>;
                    })
                }
            </ul>
        </div>
    );
};

export default ListeSalarieReleveHeure;