import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import pages
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import GestionSalariePage from './pages/Salarie/GestionSalariePage';
import VehiculeListPage from './pages/Vehicules/VehiculeListPage';
import MateriauxListPage from './pages/Materiaux/MateriauxListPage';
import GestionChantierPage from './pages/Chantier/GestionChantierPage';
import ReleveHeureChef from './pages/ReleveHeure/ReleveHeureChef';
import Absence from './pages/Absence/Absence';
import AdminReleveHeure from './pages/ReleveHeure/AdminReleveHeure';
import ReleveHeureConsultation from './pages/ReleveHeure/ReleveHeureConsultation';
import GestionArchiveSalarie from './pages/Salarie/GestionArchiveSalarie';
import BanqueHeureAdminList from './pages/BanqueHeure/BanqueHeureAdminList';
import BanqueHeureConsultationAdmin from './pages/BanqueHeure/BanqueHeureConsultationAdmin';
import GestionArchiveChantier from './pages/Chantier/GestionArchiveChantier';
import MonCompte from './pages/MonCompte';
import CategorieDocuments from './pages/Documents/CategorieDocuments';
import DocumentsPublics from './pages/Documents/DocumentsPublics';
import ProfilSalarie from './pages/Salarie/ProfilSalarie';
import OneReleveHeureConsultation from './pages/ReleveHeure/OneReleveHeureConsultation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GestionEquipements from './pages/Epi/GestionEquipements';
import Support from './pages/Support';
import ProfilChantier from './pages/Chantier/ProfilChantier';
import GestionProcessus from './pages/Processus/GestionProcessus';
import HistoriqueEquipementEpc from './pages/Materiel/HistoriqueEquipementEPC';
import DetailProcessus from './pages/Processus/DetailProcessus';
import GestionRelevesExpositions from './pages/ReleveExposition/GestionRelevesExpositions';
import ReleveExposition from './pages/ReleveExposition/ReleveExposition';
import ReleveHeureAdmin from './pages/ReleveHeure/ReleveHeureAdmin';
import ProfilSalarieNew from './pages/Salarie/ProfilSalarieNew';
import GestionFormationsSuivi from './pages/Formations/GestionFormationsSuivi';
import BanqueHeure from './pages/BanqueHeure/BanqueHeure';
import SuiviMateriel from './pages/Materiel/SuiviMateriel';
import ProfilMateriel from './pages/Materiel/ProfilMateriel';
import GestionChantierAmiante from './pages/Chantier/GestionChantierAmiante';
import ProfilChantierNew from './pages/Chantier/ProfilChantierNew';
import DocumentAmiante from './pages/Amiante/Document/DocumentAmiante';
import ActualitesAdmin from './pages/Actualites/ActualitesAdmin';
import AjouterActualites from './pages/Actualites/AjouterActualites';
import ModifierActualite from './pages/Actualites/ModifierActualite';
import VoirActualite from './pages/Actualites/VoirActualite';
import ActualiteSalarie from './pages/Actualites/ActualiteSalarie';
import EpiGestions from './pages/Amiante/EPI/EpiGestions';
import ProfilVehicule from './pages/Vehicules/ProfilVehicule';
import GestionSites from './pages/Sites/GestionSites';
import ProfilSite from './pages/Sites/ProfilSite';
import GestionSalarieAmiantePage from './pages/Salarie/GestionSalarieAmiantePage';
import ProfilSalarieAmiante from './pages/Salarie/ProfilSalarieAmiante';
import DemandeTransport from './pages/Transport/DemandeTransport';
import ProfilChantierAmiante from './pages/Chantier/ProfilChantierAmiante/ProfilChantierAmiante';
import ReglagesAmiante from './pages/Amiante/Reglages/ReglagesAmiante';
import IntervenantsTiers from './pages/Tiers/IntervenantsTiers';
import EntretienEquipement from './pages/Materiel/EntretienEquipements';
import OptionsFormulaires from './pages/Amiante/Reglages/OptionsFormulaires';
import GestionDechets from './pages/Dechets/GestionDechets';
import ActualitesArchive from './pages/Actualites/ActualitesArchive';
import ReleveHeureV2 from './pages/ReleveHeureV2/ReleveHeureV2';
import ReleveHeureAdminV2 from './pages/ReleveHeureV2/ReleveHeureAdminV2';
import OneReleveHeureConsultationV2 from './pages/ReleveHeureV2/OneReleveHeureConsultationV2';
import RelevesHeuresConsultationV2 from './pages/ReleveHeureV2/RelevesHeuresConsultationV2';

const App = () => {
  const isAuthenticated = useSelector(
    state => state.user.isAuth
  );

  const user = useSelector(
    state => state.user.user
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}>
          {/* Route ADMIN */}
          <Route path="releve-heure/releve-chef/:idChef" element={isAuthenticated && user.statut === 'Administration' ? <ReleveHeureConsultation /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/saisiePar/:idUtilisateur" element={isAuthenticated && user.statut === 'Administration' ? <RelevesHeuresConsultationV2 /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/releve/:idReleve" element={isAuthenticated && user.statut === 'Administration' ? <OneReleveHeureConsultation /> : <h1>Accès interdit</h1>} />
          {/* <Route path="releve-heure/releve-chef" element={isAuthenticated && user.statut === 'Administration' ? <AdminReleveHeure /> : <h1>Accès interdit</h1>}/> */}
          <Route path="releve-heure/releve-chef" element={isAuthenticated && user.statut === 'Administration' ? <ReleveHeureAdmin /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/dashboard" element={isAuthenticated && user.statut === 'Administration' ? <ReleveHeureAdminV2 /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/edit-releve/:idReleve" element={isAuthenticated && user.statut === 'Administration' ? <OneReleveHeureConsultationV2 /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/banque-salaries/:idUtilisateur" element={isAuthenticated && user.statut === 'Administration' ? <BanqueHeureConsultationAdmin /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/banque-salaries" element={isAuthenticated && user.statut === 'Administration' ? <BanqueHeureAdminList /> : <h1>Accès interdit</h1>} />
          <Route path="releve-heure/absence" element={isAuthenticated && user.statut === 'Administration' ? <Absence /> : <h1>Accès interdit</h1>} />
          <Route path="transport/vehicules" element={isAuthenticated && (user.statut === 'Administration' || user.statut === 'Dispatcher') ? <VehiculeListPage /> : <h1>Accès interdit</h1>} />
          <Route path="transport/vehicules/profil/:idVehicule" element={isAuthenticated && (user.statut === 'Administration' || user.statut === 'Dispatcher') ? <ProfilVehicule /> : <h1>Accès interdit</h1>} />
          <Route path="transport/demande" element={isAuthenticated && (user.statut === 'Administration' || user.statut === 'Dispatcher') ? <DemandeTransport /> : <h1>Accès interdit</h1>} />
          <Route path="amiante" element={isAuthenticated && (user.statut === 'Administration' || user.statut === 'Encadrant technique') ? <h1 className="h1-blue-dark">Amiante</h1> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/salaries" element={isAuthenticated && user.statut === 'Administration' ? <GestionSalariePage /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/chantiers" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher") ? <GestionChantierPage /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/chantiers/:idChantier" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher") ? <ProfilChantierNew /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/archive-salarie" element={isAuthenticated && user.statut === 'Administration' ? <GestionArchiveSalarie /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/sites" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher" || user.statut === "Encadrant technique") ? <GestionSites /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/sites/profil/:idSite" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher" || user.statut === "Encadrant technique") ? <ProfilSite /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/materiaux" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher" || user.statut === "Encadrant technique") ? <MateriauxListPage /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/dechets" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionDechets /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/archive-chantier" element={isAuthenticated && user.statut === 'Administration' ? <GestionArchiveChantier /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/equipements" element={isAuthenticated && user.statut === 'Administration' ? <GestionEquipements /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/equipements" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionEquipements /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/suivi-materiel" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <SuiviMateriel /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/suivi-materiel/:idMateriel" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <ProfilMateriel /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/salaries" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionSalarieAmiantePage /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/suivi-entretiens" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <EntretienEquipement /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/suivi-entretiens/:idEntretien" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <ProfilMateriel /> : <h1>Accès interdit</h1>} />          <Route path="amiante/salaries/profil/:idSalarie" element={isAuthenticated && user.statut === 'Administration' || "Encadrant technique" ? <ProfilSalarieAmiante /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/processus" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionProcessus /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/equipements/historique/:idControle" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <HistoriqueEquipementEpc /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/releve-exposition" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionRelevesExpositions /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/releve-exposition/:idReleveExposition" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <ReleveExposition /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/processus/:idProcessus" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <DetailProcessus /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/chantiers" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionChantierAmiante /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/chantiers/profil/:idChantier" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique" || user.statut === "Dispatcher") ? <ProfilChantierAmiante /> : <h1>Accès interdit</h1>} />
          <Route path="amiante/documents-amiante" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <DocumentAmiante /> : <h1>Accès interdit</h1>} />
          <Route path="documents/categories" element={isAuthenticated && user.statut === 'Administration' ? <CategorieDocuments /> : <h1>Accès interdit</h1>} />
          <Route path="documents/publics" element={isAuthenticated ? <DocumentsPublics /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/salaries/profilOld/:idSalarie" element={isAuthenticated && user.statut === 'Administration' ? <ProfilSalarie /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/intervenants/" element={isAuthenticated && user.statut === 'Administration' ? <IntervenantsTiers /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/salaries/profil/:idSalarie" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Dispatcher") ? <ProfilSalarieNew /> : <h1>Accès interdit</h1>} />
          <Route path="gestion-de-donnees/formations-suivis/" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <GestionFormationsSuivi /> : <h1>Accès interdit</h1>} />
          <Route path="/justeau-actus/actualites/" element={isAuthenticated ? user.statut === 'Administration' ? <ActualitesAdmin /> : <ActualiteSalarie /> : <h1>Accès interdit</h1>} />
          <Route path="/justeau-actus/archives-actualites" element={isAuthenticated && user.statut === 'Administration' ? <ActualitesArchive /> : <h1>Accès interdit</h1>} />
          <Route path="/justeau-actus/actualites/:actualiteId" element={isAuthenticated ? <VoirActualite /> : <h1>Accès interdit</h1>} />
          <Route path="/justeau-actus/ajouter-actualite/" element={isAuthenticated && user.statut === 'Administration' ? <AjouterActualites /> : <h1>Accès interdit</h1>} />
          <Route path="/justeau-actus/modifier-actualite/:actualiteId" element={isAuthenticated && user.statut === 'Administration' ? <ModifierActualite /> : <h1>Accès interdit</h1>} />
          <Route path="reglages/epi" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <EpiGestions /> : <h1>Accès interdit</h1>} />
          <Route path="reglages/amiante" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <ReglagesAmiante /> : <h1>Accès interdit</h1>} />
          <Route path="reglages/options-formulaires" element={isAuthenticated && (user.statut === 'Administration' || user.statut === "Encadrant technique") ? <OptionsFormulaires /> : <h1>Accès interdit</h1>} />
          {/* Route CHEF */}
          <Route path="releve-heures-chef" element={isAuthenticated && (user.statut === 'Chef de chantier' || user.statut === "Encadrant technique" || user.statut === "Encadrant de chantier") ? <ReleveHeureChef /> : <h1>Accès interdit</h1>} />
          <Route path="mes-releves-heures" element={isAuthenticated && (user.statut === 'Chef de chantier' || user.statut === "Encadrant technique" || user.statut === "Encadrant de chantier") ? <ReleveHeureChef /> : <h1>Accès interdit</h1>} />
          {/* <Route path="mes-releves-heures" element={isAuthenticated && (user.statut === 'Chef de chantier' || user.statut === "Encadrant technique" || user.statut === "Encadrant de chantier" || user.statut === "Administration") ? <ReleveHeureV2 /> : <h1>Accès interdit</h1>} /> */}
          <Route path="ma-banque-heures" element={isAuthenticated && (user.statut === 'Chef de chantier' || user.statut === "Ouvrier" || user.statut === "Intérimaire" || user.statut === "Encadrant technique" || user.statut === "Encadrant de chantier" || user.statut === "Opérateur") ? <BanqueHeure /> : <h1>Accès interdit</h1>} />
          {/* Route ALL */}
          <Route path="mon-compte" element={isAuthenticated ? <MonCompte /> : <h1>Accès interdit</h1>} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </Router>
  );
};

// code ECT: QBca3tms

export default App;
