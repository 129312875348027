import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import InsideNavigationBar from "../../components/ui/InsideNavigationBar";
import Hider from 'react-hider';
import SearchInput from "../../components/ui/SearchInput";
import { getSecteursActivite } from "../../utils/GeneralHelper";
import TablePaginationV2 from "../../components/tables/Render/TablePaginationV2";
import { getReleveHeureParChefColumns, getReleveHeureParSemaineColumns } from "../../components/tables/Basic/BasicTableColumns";
import axiosInstance from "../../utils/axiosInstance";
import { showError } from "../../utils/ErrorHelper";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/exportAction";
import Select from 'react-select';
import Modal from "../../components/Modal";
import ListeSalarieReleveHeureV2 from "./Modal/ListeSalarieReleveHeureV2";

const savePageOptions = (pageName, pageOptions) => (actionCreators.savePageOptions(pageName, pageOptions));

const ReleveHeureAdminV2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageOptionsSaved = useSelector(
        state => state.pageState
    );

    const progressBarRef = useRef(null);

    const [pageOptions, setPageOptions] = useState({
        page: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("page") ? pageOptionsSaved.ReleveHeureAdminV2.page : 1,
        limit: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("limit") ? pageOptionsSaved.ReleveHeureAdminV2.limit : 10,
        total: 0,
        sort: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("sort") ? pageOptionsSaved.ReleveHeureAdminV2.sort : "nom",
        sortBy: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("sortBy") ? pageOptionsSaved.ReleveHeureAdminV2.sortBy : "asc",
        search: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("search") ? pageOptionsSaved.ReleveHeureAdminV2.search : "",
        secteur: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("secteur") ? pageOptionsSaved.ReleveHeureAdminV2.secteur : "All",
        totalPages: 1,
        fetchData: true
    });

    const [tableData, setTableData] = useState([]);


    const [afficherRelevePar, setAfficherRelevePar] = useState("Chef");

    const fetchData = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/releveParSalarie?page=${pageOptions.page}&limit=${pageOptions.limit}&sort=${pageOptions.sort}&sortBy=${pageOptions.sortBy}&search=${pageOptions.search}&secteur=${pageOptions.secteur}`).then((result) => {
            progressBarRef.current.complete();
            setTableData(result.data.result);
            setPageOptions({ ...pageOptions, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total, fetchData: false });
        }).catch((err) => {
            progressBarRef.current.complete();
            showError(err);
        });

    };

    useEffect(() => {
        if (pageOptions.fetchData) {
            fetchData();
            setPageOptions({ ...pageOptions, fetchData: false });
        }
        return () => {
            if (pageOptions.fetchData) {
                dispatch(savePageOptions("ReleveHeureAdminV2", { page: pageOptions.page, limit: pageOptions.limit, sort: pageOptions.sort, sortBy: pageOptions.sortBy, search: pageOptions.search, secteur: pageOptions.secteur }));
            }
        };
    }, [pageOptions]);

    const setPageOptionsFetch = (options) => {
        setPageOptions({ ...pageOptions, ...options, fetchData: true });
    };

    // SEARCHBAR
    const [searchChefBarContent, setSearchChefBarContent] = useState(null);

    const onChangeSearchChef = (e) => {
        setSearchChefBarContent(e.target.value);
    };

    useEffect(() => {
        if (searchChefBarContent !== null) {
            let timer = setTimeout(() => {
                setPageOptionsFetch({ "search": searchChefBarContent });
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchChefBarContent]);

    const [pageOptionsReleveParSemaine, setPageOptionsReleveParSemaine] = useState({
        page: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("page") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.page : 1,
        limit: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("limit") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.limit : 10,
        total: 0,
        sort: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("sort") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.sort : "annee",
        sortBy: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("sortBy") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.sortBy : "asc",
        annee: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("annee") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.annee : "",
        numeroSemaine: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("numeroSemaine") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.numeroSemaine : "",
        statutSemaine: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("statutSemaine") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.statutSemaine : "",
        saisiePar: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("saisiePar") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.saisiePar : "",
        utilisateurPresent: pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2_Semaine") && pageOptionsSaved.ReleveHeureAdminV2_Semaine.hasOwnProperty("utilisateurPresent") ? pageOptionsSaved.ReleveHeureAdminV2_Semaine.utilisateurPresent : "",
        totalPages: 1,
        fetchData: true
    });

    const [tableDataReleveParSemaine, setTableDataReleveParSemaine] = useState([]);

    const fetchDataReleveParSemaine = () => {
        progressBarRef.current.continuousStart();
        axiosInstance.get(`/releve-heure-v2/statutsSemaine?page=${pageOptionsReleveParSemaine.page}&limit=${pageOptionsReleveParSemaine.limit}&sort=${pageOptionsReleveParSemaine.sort}&sortBy=${pageOptionsReleveParSemaine.sortBy}&annee=${pageOptionsReleveParSemaine.annee ? pageOptionsReleveParSemaine.annee.value : ""}&numeroSemaine=${pageOptionsReleveParSemaine.numeroSemaine ? pageOptionsReleveParSemaine.numeroSemaine.value : ""}&statutSemaine=${pageOptionsReleveParSemaine.statutSemaine ? pageOptionsReleveParSemaine.statutSemaine.value : ""}&saisiePar=${pageOptionsReleveParSemaine.saisiePar ? pageOptionsReleveParSemaine.saisiePar.value : ""}&utilisateurPresent=${pageOptionsReleveParSemaine.utilisateurPresent ? pageOptionsReleveParSemaine.utilisateurPresent.value : ""}`).then((result) => {
            progressBarRef.current.complete();
            setTableDataReleveParSemaine(result.data.statutsSemaine);
            setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, page: result.data.currentPage, totalPages: result.data.totalPages, total: result.data.total, fetchData: false });
        }).catch((err) => {
            progressBarRef.current.complete();
            console.log(err);
            showError(err);
        });
    };

    useEffect(() => {
        if (pageOptionsReleveParSemaine.fetchData) {
            fetchDataReleveParSemaine();
            setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, fetchData: false });
        }
        return () => {
            if (pageOptionsReleveParSemaine.fetchData) {
                dispatch(savePageOptions("ReleveHeureAdminV2_Semaine", { page: pageOptionsReleveParSemaine.page, limit: pageOptionsReleveParSemaine.limit, sort: pageOptionsReleveParSemaine.sort, sortBy: pageOptionsReleveParSemaine.sortBy, annee: pageOptionsReleveParSemaine.annee, numeroSemaine: pageOptionsReleveParSemaine.numeroSemaine, statutSemaine: pageOptionsReleveParSemaine.statutSemaine, saisiePar: pageOptionsReleveParSemaine.saisiePar, utilisateurPresent: pageOptionsReleveParSemaine.utilisateurPresent }));
            }
        };
    }, [pageOptionsReleveParSemaine]);

    const setPageOptionsFetchReleveParSemaine = (options) => {
        setPageOptionsReleveParSemaine({ ...pageOptionsReleveParSemaine, ...options, fetchData: true });
    };

    const [anneeReleveParSemaineOptions, setAnneeReleveParSemaineOptions] = useState([]);
    const [numeroSemaineReleveParSemaineOptions, setNumeroSemaineReleveParSemaineOptions] = useState([]);
    const [saisieParUtilisateursReleveParSemaineOptions, setSaisieParUtilisateursReleveParSemaineOptions] = useState([]);
    const [listeDesSalaries, setListeDesSalaries] = useState({});


    useEffect(() => {
        axiosInstance.get("/releve-heure-v2/statutsSemaine/annees").then((result) => {
            let anneeOptions = [{ label: "Toutes les années", value: "All", semaines: [] }];
            for (const options of result.data) {
                anneeOptions.push({ label: options.annee, value: options.annee, semaines: options.semaines });
            }
            setAnneeReleveParSemaineOptions(anneeOptions);
        });

        axiosInstance.get("/releve-heure-v2/listeUtilisateursSaisiePar").then((result) => {
            let optionsSaisieParListe = [{ label: "Tous les utilisateurs", value: "All" }];
            for (const optionsSaisiePar of result.data) {
                optionsSaisieParListe.push({ label: `(${optionsSaisiePar.login_matricule}) - ${optionsSaisiePar.prenom} ${optionsSaisiePar.nom}`, value: optionsSaisiePar._id });
            }
            setSaisieParUtilisateursReleveParSemaineOptions(optionsSaisieParListe);
        });

        axiosInstance.get('/utilisateurs').then((result) => {
            setListeDesSalaries(result.data);
        }).catch((err) => {
            showError(err);
        });
    }, []);

    const onChangeAnnee = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "annee": opt });
        if (opt !== null) {
            let optionsNumeroSemaine = [{ label: "Toutes les semaines", value: "All" }];
            for (const numeroSemaine of opt.semaines) {
                optionsNumeroSemaine.push({ label: numeroSemaine, value: numeroSemaine });
            }
            setNumeroSemaineReleveParSemaineOptions(optionsNumeroSemaine);
        }
        else {
            setNumeroSemaineReleveParSemaineOptions([]);
        }
    };

    const onChangeNumeroSemaine = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "numeroSemaine": opt });
    };

    const onChangeSaisiePar = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "saisiePar": opt });
    };

    const onChangeStatutSemaine = (opt) => {
        setPageOptionsFetchReleveParSemaine({ "statutSemaine": opt });
    };

    const handleChangeSalarie = (...args) => {
        setPageOptionsFetchReleveParSemaine({ "utilisateurPresent": args[0] });
    };

    const optionsSalarie = [
        {
            label: "Ouvrier",
            options: listeDesSalaries.hasOwnProperty("ouvrierList") && listeDesSalaries.ouvrierList.length ? listeDesSalaries.ouvrierList.map((ouvrier) => {
                let fullName = ouvrier._id.login_matricule + " / " + ouvrier._id.nom + " " + ouvrier._id.prenom;
                return { label: fullName, value: ouvrier._id._id };
            }) : []
        },
        {
            label: "Chef de chantier",
            options: listeDesSalaries.hasOwnProperty("chefChantierList") && listeDesSalaries.chefChantierList.length ? listeDesSalaries.chefChantierList.map((chef) => {
                let fullName = chef._id.login_matricule + " / " + chef._id.nom + " " + chef._id.prenom;
                return { label: fullName, value: chef._id._id };
            }) : []
        },
        {
            label: "Intérimaire",
            options: listeDesSalaries.hasOwnProperty("interimaireList") && listeDesSalaries.interimaireList.length ? listeDesSalaries.interimaireList.map((interimaire) => {
                let fullName = interimaire._id.login_matricule + " / " + interimaire._id.nom + " " + interimaire._id.prenom;
                return { label: fullName, value: interimaire._id._id };
            }) : []
        }
    ];

    // Gestion Modal
    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openSalariePresentSemaine = (idSemaine) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ListeSalarieReleveHeureV2 idSemaine={idSemaine} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    return (
        <>
            <LoadingBar color={"#d13852"} ref={progressBarRef} />
            <div className="section">
                <h1>Relevé d'heures</h1>
                <InsideNavigationBar
                    activeValue={afficherRelevePar}
                    setActiveValue={(value) => setAfficherRelevePar(value)}
                    links={[
                        { label: "Relevé par chefs de chantier", value: "Chef" },
                        { label: "Relevé par semaine", value: "Releve" },
                        { label: "Exporter des relevés d'heures", value: "Export" }
                    ]}
                />
                <Hider state={afficherRelevePar === "Chef"}>
                    <>
                        <div className="filterContainer">
                            <SearchInput fullwidth={true} placeholder="Rechercher un relevé par nom, prenom, matricule..." searchContent={searchChefBarContent ? searchChefBarContent : pageOptionsSaved.hasOwnProperty("ReleveHeureAdminV2") && pageOptionsSaved.ReleveHeureAdminV2.hasOwnProperty("search") ? pageOptionsSaved.ReleveHeureAdminV2.search : ""} onChangeSearch={onChangeSearchChef} />
                            <select value={pageOptions.secteur} className="new-default-input" onChange={(e) => setPageOptionsFetch({ "secteur": e.target.value })} >
                                <option value="All">Tout les secteurs</option>
                                {getSecteursActivite().map((secteur) => {
                                    return <option key={secteur} value={secteur}>{secteur}</option>;
                                })}
                            </select>
                        </div>
                        <TablePaginationV2
                            columns={getReleveHeureParChefColumns((url) => navigate(url))}
                            tableData={tableData}
                            pageOptions={pageOptions}
                            setPageOptionsFetch={setPageOptionsFetch}
                        />
                    </>
                </Hider>
                <Hider state={afficherRelevePar === "Releve"}>
                    <>
                        <div className="filterContainer">
                            <Select
                                options={anneeReleveParSemaineOptions}
                                onChange={onChangeAnnee}
                                placeholder="Sélectionner une année"
                                className="formSelect-search-container"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    }),
                                }}
                                isSearchable={true}
                                value={pageOptionsReleveParSemaine.annee}
                            />
                            <Select
                                options={numeroSemaineReleveParSemaineOptions}
                                className="formSelect-search-container"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeNumeroSemaine(opt)}
                                placeholder="Sélectionner le numéro de semaine"
                                value={pageOptionsReleveParSemaine.numeroSemaine}
                            />
                            <Select
                                options={saisieParUtilisateursReleveParSemaineOptions}
                                onChange={onChangeSaisiePar}
                                placeholder="Sélectionner un chef de chantier"
                                className="formSelect-search-container"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isSearchable={true}
                                value={pageOptionsReleveParSemaine.saisiePar}
                            />
                            <Select
                                options={[{ value: "All", label: "Voir tous les relevés de semaine" }, { value: 0, label: "En cours" }, { value: 1, label: "Terminée" }]}
                                className="formSelect-search-container"
                                classNamePrefix="formSelect-search"
                                onChange={(opt) => onChangeStatutSemaine(opt)}
                                placeholder="Sélectionner le statut de la semaine"
                                value={pageOptionsReleveParSemaine.statutSemaine}
                            />
                            <Select
                                options={optionsSalarie}
                                value={pageOptionsReleveParSemaine.utilisateurPresent === null ? '' : pageOptionsReleveParSemaine.utilisateurPresent}
                                placeholder="Sélectionner un salarié présent dans la semaine"
                                isSearchable={true}
                                onChange={handleChangeSalarie}
                                className="formSelect-search-container"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        border: state.isFocused || state.menuIsOpen ? "1px solid #2b2b2b" : '1px solid #d9d9d9',
                                        borderRadius: "0.6rem",
                                        boxShadow: state.menuIsOpen ? "0 0 0 2px rgb(3 3 3 / 64%)" : 'none',
                                        '&:hover': {
                                            border: '1px solid black',
                                        },
                                    })
                                }}
                                isClearable={true}
                            />
                        </div>
                        <TablePaginationV2
                            columns={getReleveHeureParSemaineColumns((idSemaine) => openSalariePresentSemaine(idSemaine), (url) => navigate(url))}
                            tableData={tableDataReleveParSemaine}
                            pageOptions={pageOptionsReleveParSemaine}
                            setPageOptionsFetch={setPageOptionsFetchReleveParSemaine}
                        />
                    </>
                </Hider>
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>

        </>
    );
};

export default ReleveHeureAdminV2;
