import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import * as actionCreators from '../../store/actions/exportAction';
import { useDispatch, useSelector } from 'react-redux';
import LiReleveHeureConsultation from './LiReleveHeureConsultation';
import customToast from '../../utils/ToastifyHelper';
import { showError } from '../../utils/ErrorHelper';


const onLoadPage = (dispatch, idChef) => {
    dispatch(actionCreators.getUserList());
    dispatch(actionCreators.getAdminReleveHeureChef(idChef));
    dispatch(actionCreators.getChantierZoneListReleve());
    dispatch(actionCreators.getAbsenceList());
    dispatch(actionCreators.getChantierOptions());
    // liste des absences laps de temps semaines 
};

const onQuitPage = (dispatch) => {
    dispatch(actionCreators.unloadAdminReleveHeure());
    dispatch(actionCreators.unloadChantierZoneList());
    dispatch(actionCreators.unloadAbsenceList());
    dispatch(actionCreators.unloadUserList());
};


const ReleveHeureConsultation = () => {
    const dispatch = useDispatch();
    let { idChef } = useParams();

    const [chefChantier, setChefChantier] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/chefs_chantier/${idChef}`)
            .then((result) => {
                setChefChantier(result.data._id);
            }).catch((err) => {
                showError(err);
            });
        onLoadPage(dispatch, idChef);
        return () => {
            onQuitPage(dispatch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idChef]);

    const releveHeureState = useSelector(
        state => state.releveHeureAdminRdc
    );

    return (
        <div className='section'>
            <div className="container-basic mgB-m2">
                <h1 className="h1-blue-dark">Tous les relevés d'heures saisis par {chefChantier !== null && chefChantier.nom} {chefChantier !== null && chefChantier.prenom}</h1>
            </div>
            <ul className="releveHeureList">
                {
                    releveHeureState.releveHeure.map((releveHeure) => {
                        return (<li className='container-basic mgB-m2' key={releveHeure._id}><LiReleveHeureConsultation releveHeure={releveHeure} /></li>);
                    })
                }
            </ul>
        </div>
    );
};

export default ReleveHeureConsultation;