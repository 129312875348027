import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../utils/axiosInstance';
import * as actionCreators from '../../../store/actions/exportAction';
import customToast from '../../../utils/ToastifyHelper';
import { groupHoraireBySalarie, totalHoraireSalarie } from '../../../utils/GeneralHelper';
import { showError } from '../../../utils/ErrorHelper';

const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));

const ValiderSemaineChef = (props) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const joursState = useSelector(
        state => state.releveHeure.jours
    );
    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onValiderSemaine = () => {
        setButtonDisabled(true);
        axiosInstance.patch('/horaires_semaines/valider_semaine/me&' + props.semaineData.annee + "&" + props.semaineData.numeroSemaine)
            .then((result) => {
                customToast.success("La semaine à été validée avec succès");
                dispatch(loadHoraireOnChange(props.semaineData.annee, props.semaineData.numeroSemaine));
                close();
            }).catch((err) => {
                setButtonDisabled(false);
                showError(err);
            });
        setButtonDisabled(false);
    };

    const horaireSemaineTotal = groupHoraireBySalarie(joursState);

    return (
        <div className="modal-content supprimerForm" style={{ display: "flex", flexDirection: "column" }}>
            <h1 className='modal-title'>Valider la semaine</h1>
            <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
            <hr />
            <div className="textAndResume-container">
                <p className="modal-confirmation-text">Comfirmer la validation de la semaine ?</p>
                <ul className="resumeSalarieHeure">
                    {Object.keys(horaireSemaineTotal.chefChantier).length !== 0 && <h3>Chef de chantier</h3>}
                    {Object.keys(horaireSemaineTotal.chefChantier).length !== 0 &&
                        Object.keys(horaireSemaineTotal.chefChantier).map((chefChantierId) => {
                            return <li key={chefChantierId}>{horaireSemaineTotal.chefChantier[chefChantierId].nom} {horaireSemaineTotal.chefChantier[chefChantierId].prenom} {totalHoraireSalarie(horaireSemaineTotal.chefChantier[chefChantierId].horaire)}</li>;
                        })
                    }
                    {Object.keys(horaireSemaineTotal.ouvrier).length !== 0 && <h3>Ouvrier</h3>}
                    {Object.keys(horaireSemaineTotal.ouvrier).length !== 0 &&
                        Object.keys(horaireSemaineTotal.ouvrier).map((ouvrierId) => {
                            return <li key={ouvrierId}>{horaireSemaineTotal.ouvrier[ouvrierId].nom} {horaireSemaineTotal.ouvrier[ouvrierId].prenom} {totalHoraireSalarie(horaireSemaineTotal.ouvrier[ouvrierId].horaire)}</li>;
                        })
                    }
                    {Object.keys(horaireSemaineTotal.interimaire).length !== 0 && <h3>Interimaire</h3>}
                    {Object.keys(horaireSemaineTotal.interimaire).length !== 0 &&
                        Object.keys(horaireSemaineTotal.interimaire).map((interimaireId) => {
                            return <li key={interimaireId}>{horaireSemaineTotal.interimaire[interimaireId].nom} {horaireSemaineTotal.interimaire[interimaireId].prenom} {totalHoraireSalarie(horaireSemaineTotal.interimaire[interimaireId].horaire)}</li>;
                        })
                    }
                </ul>
            </div>
            <div className="button-container" style={{ flex: 1, alignItems: "flex-end" }}>
                <button disabled={isButtonDisabled} className='btn btn-secondary medium-btn fw mgR-m1' onClick={() => close()}>Annuler</button>
                <button disabled={isButtonDisabled} className='btn btn-primary medium-btn fw' onClick={() => onValiderSemaine()}>Valider</button>
            </div>
        </div>
    );
};

export default ValiderSemaineChef;