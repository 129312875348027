import React, { useState } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import axiosInstance from '../utils/axiosInstance';
import * as actionCreators from '../store/actions/exportAction';
import { useParams } from 'react-router-dom';
import customToast from '../utils/ToastifyHelper';
import { showError } from '../utils/ErrorHelper';

const reloadReleveHeureAdmin = (idChef) => (actionCreators.getAdminReleveHeureChef(idChef));

const CopierVersJourAdmin = (props) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  let { idChef } = useParams();
  const dispatch = useDispatch();
  const close = () => {
    props.closeModal();
    props.unrenderForm();
  };

  const onValiderSuppression = () => {
    setButtonDisabled(true);
    const postData = { date_jour_origine: format(props.jourData.origine, "yyyy-MM-dd"), date_jour_destination: format(props.jourData.destination, "yyyy-MM-dd") };
    axiosInstance.patch(`/horaires_semaines/copierJour/${idChef}&${props.annee}&${props.numeroSemaine}`, postData)
      .then((result) => {
        customToast.success("Copie des horaires effectuées, n'oubliez pas de compléter les horaires copiées.");
        dispatch(reloadReleveHeureAdmin(idChef));
        close();
      }).catch((err) => {
        setButtonDisabled(false);
        showError(err);
      });
  };

  return (
    <div className="supprimerForm">
      <h1 className='modal-title'>Copier les horaires du {format(props.jourData.origine, "dd/MM/yyyy")} vers le {format(props.jourData.destination, "dd/MM/yyyy")} </h1>
      <button disabled={isButtonDisabled} className='modal-button' onClick={() => close()}>x</button>
      <hr />
      <p className="modal-confirmation-text">Appuyer sur valider pour confirmer la copie des horaires. Attention la copie <span className="suppression-mot">remplace</span> toutes les données saisie le {format(props.jourData.destination, "dd/MM/yyyy")}</p>
      <div className="button-container">
        <button disabled={isButtonDisabled} className='default-button supprimer-form-annuler' onClick={() => close()}>Annuler</button>
        <button disabled={isButtonDisabled} className='default-button supprimer-form-valider' onClick={() => onValiderSuppression()}>Valider</button>
      </div>


    </div>
  );
};

export default CopierVersJourAdmin;