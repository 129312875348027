import React, { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import { Icon } from '@iconify/react';
import SupprimerIcon from './icon/SupprimerIcon';
import { format } from 'date-fns';
import { truncateString } from '../utils/GeneralHelper';

const ReleveHeureDotMenu = (props) => {

    const [menuPosition, setMenuPosition] = useState(null);
    const [className, setClassName] = useState("threeDot-button");

    const dotOpenAction = (event) => {
        setClassName("threeDot-button hovered");
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    // const handleItemClick = (event) => {
    //   setMenuPosition(null);
    // };

    const handleCopierVers = (copieData) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        props.copierHoraireVers(copieData);
    };

    const handleSupprimerChantierHoraire = (jour, chantier) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        props.supprimerHeuresChantier(jour, chantier);
    };

    const handleSupprimerToutHoraires = (chantierJour) => {
        setMenuPosition(null);
        setClassName("threeDot-button");
        props.supprimerTouteHoraires(chantierJour);
    };

    return (
        <>
            <button className={className} disabled={props.statutSemaine === 0 || props.statutSemaine === null ? false : true} onClick={(e) => dotOpenAction(e)}><Icon icon="bi:three-dots" /></button>
            <Menu open={!!menuPosition} onClose={() => { setMenuPosition(null); setClassName("threeDot-button"); }} anchorReference="anchorPosition" anchorPosition={menuPosition}>
                {/* <MenuItem onClick={handleItemClick}>Button 1</MenuItem>
                <MenuItem onClick={handleItemClick}>Button 2</MenuItem>
                <NestedMenuItem label="Button 3" parentMenuOpen={!!menuPosition} onClick={handleItemClick}>
                    <MenuItem onClick={handleItemClick}>Sub-Button 1</MenuItem>
                    <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
                    <NestedMenuItem label="Sub-Button 3" parentMenuOpen={!!menuPosition} onClick={handleItemClick}>
                        <MenuItem onClick={handleItemClick}>Sub-Sub-Button 1</MenuItem>
                        <MenuItem onClick={handleItemClick}>Sub-Sub-Button 2</MenuItem>
                    </NestedMenuItem>
                </NestedMenuItem> */}
                <NestedMenuItem label={<p className="dotMenu-label"><Icon icon="fluent:copy-arrow-right-24-regular" />Copier vers</p>} parentMenuOpen={!!menuPosition}>
                    {props.allDays.map((jour) => {
                        if (jour !== props.currentDay) {
                            return <MenuItem key={format(jour, "dd/MM/yyyy")} onClick={() => handleCopierVers({ origine: props.currentDay, destination: jour })}>{format(jour, "dd/MM/yyyy")}</MenuItem>;
                        }
                        return null;
                    })}
                </NestedMenuItem>
                <NestedMenuItem label={<p className="dotMenu-label"><SupprimerIcon />Supprimer</p>} parentMenuOpen={!!menuPosition}>
                    <MenuItem onClick={() => handleSupprimerToutHoraires(props.currentDay)}>Supprimer toutes les heures</MenuItem>
                    {props.chantiers !== null && props.chantiers.map((chantier) => {
                        return <MenuItem key={chantier._id._id} onClick={() => handleSupprimerChantierHoraire({ date_jour: props.currentDay, idChantier: chantier._id._id, nomChantier: chantier._id.libelle_lieu })}>{truncateString(chantier._id.libelle_lieu, 16)}</MenuItem>;

                    })}
                </NestedMenuItem>
            </Menu>
        </>
    );
};

export default ReleveHeureDotMenu;