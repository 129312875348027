import React, { useState, useRef, } from 'react';
// import SelectSearch from 'react-select-search';
import customToast from '../../../utils/ToastifyHelper';
import * as actionCreators from '../../../store/actions/exportAction';
import axiosInstance from '../../../utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import "react-time-picker-input/dist/components/TimeInput.css";
import { formatIntTwoDigitString } from '../../../utils/GeneralHelper';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { showError } from '../../../utils/ErrorHelper';

const reloadReleveHeureAdmin = (idChef) => (actionCreators.getAdminReleveHeureChef(idChef));


const ModifierSalarieAbsenceAdmin = (props) => {
    let { idChef } = useParams();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [formErrorSalarie, setFormErrorSalarie] = useState(null);
    const [salarieSelectedId, setSalarieSelectedId] = useState(props.jourSalarie.absence.utilisateur_absent._id);
    const dispatch = useDispatch();

    const userListState = useSelector(state => state.userList);

    const [tempsAbsence, setTempsAbsence] = useState(
        props.jourSalarie.absence.tempsAbsence.heure === null ? null : formatIntTwoDigitString(props.jourSalarie.absence.tempsAbsence.heure) + ":" + formatIntTwoDigitString(props.jourSalarie.absence.tempsAbsence.minute)
    );

    const close = () => {
        props.closeModal();
        props.unrenderForm();
    };

    const onSubmit = data => {
        // verifier si absence existe déjà
        setButtonDisabled(true);
        if (salarieSelectedId === null) {
            setFormErrorSalarie(true);
        }
        if ((formErrorSalarie === false || salarieSelectedId !== null)) {
            let heure, minute = null;
            if (tempsAbsence !== null) {
                const temps = tempsAbsence.match(/^(\d+):(\d+)/);
                if (temps !== null) {
                    heure = parseInt(temps[1]);
                    minute = parseInt(temps[2]);
                }
            }
            let postData = {
                utilisateur_absent: salarieSelectedId,
                commentaire: data.commentaire,
                type_absence: data.TypeAbsence,
                heure: heure,
                minute: minute,
                date_deb_absence: format(new Date(props.jourSalarie.absence.date_deb_absence), "yyyy-MM-dd"),
                date_fin_absence: format(new Date(props.jourSalarie.absence.date_deb_absence), "yyyy-MM-dd")
            };
            axiosInstance.patch(`/absences/${props.jourSalarie.absence.utilisateur_absent._id}&${format(new Date(props.jourSalarie.absence.date_deb_absence), "yyyy-MM-dd")}`, postData)
                .then((result) => {
                    customToast.success("L'abscence à été modifié avec succès");
                    dispatch(reloadReleveHeureAdmin(idChef));
                    close();
                }).catch((err) => {
                    setButtonDisabled(false);
                    showError(err);
                });
        }
        else {
            setButtonDisabled(false);
        }
    };

    const schema = yup.object().shape({
        TypeAbsence: yup.string().required("Veuillez sélectionner un type d'absence"),
        commentaire: yup.string()
    });
    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const searchInput = useRef();

    const ouvrierOptions = userListState.ouvrierList.map((ouvrier) => {
        let fullName = ouvrier._id.nom + " " + ouvrier._id.prenom;
        return { name: fullName, value: ouvrier._id._id };
    });
    const chefChantierOptions = userListState.chefChantierList.map((chef) => {
        let fullName = chef._id.nom + " " + chef._id.prenom;
        return { name: fullName, value: chef._id._id };
    });
    const interimaireOptions = userListState.interimaireList.map((interimaire) => {
        let fullName = interimaire._id.nom + " " + interimaire._id.prenom;
        return { name: fullName, value: interimaire._id._id };
    });

    const options = [
        {
            type: 'group',
            name: "Ouvrier",
            items: ouvrierOptions
        },
        {
            type: 'group',
            name: "Chef de chantier",
            items: chefChantierOptions
        },
        {
            type: 'group',
            name: "Intérimaire",
            items: interimaireOptions
        }
    ];

    const handleChange = (...args) => {
        // console.log("ARGS:", args);
        // console.log("CHANGE:");
        setFormErrorSalarie(false);
        setSalarieSelectedId(args[0]);
    };

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    // const [valueDateAbsence, onChange] = useState([new Date(props.jourSalarie.absence.date_deb_absence), props.jourSalarie.absence.date_fin_absence === null ? null : new Date(props.jourSalarie.absence.date_fin_absence)]);

    return (
        <div className="modal-content modifier-form">
            <h3 className="modal-title">Modifier une absence</h3>
            <button className='modal-button' onClick={() => close()}>x</button>
            <form className="vertical-form scrollable" onSubmit={handleSubmit(onSubmit)}>
                <label className='default-label' htmlFor="salarie">Choisir un salarié</label>
                <SelectSearch
                    ref={searchInput}
                    options={options}
                    filterOptions={handleFilter}
                    value={salarieSelectedId}
                    name="salarie"
                    placeholder="Choisir un salarié"
                    search
                    onChange={handleChange}
                />
                {formErrorSalarie && <p className="login-error-message">Veuillez sélectionner un salarié</p>}
                <label className='ajouter-form-label default-label' htmlFor="typeAbsence">Choisir un type d'absence</label>
                <select className='new-default-input default-select' name="typeAbsence" defaultValue={props.jourSalarie.absence.type_absence} {...register("TypeAbsence", { required: true })}>
                    <option value="Arrêt de travail">Arrêt de travail</option>
                    <option value="Maladie">Maladie</option>
                    <option value="Congés">Congés payés</option>
                    <option value="Ecole">Ecole/Formation</option>
                    <option value="Autre">Autre</option>
                    <option value="Absence chantier">Absence chantier</option>
                </select>
                <label className='ajouter-form-label default-label' >Temps d'absence</label>
                <TimePicker showLeadingZeros={true} className="input-datetimePicker" disableClock onChange={setTempsAbsence} format="HH:mm" value={tempsAbsence} />
                <label className='ajouter-form-label default-label' htmlFor="commentaireAbsence">Commentaire</label>
                <input className='default-input' type="text" placeholder="Commentaire" defaultValue={props.jourSalarie.absence.commentaire} {...register("commentaire", {})} />
                <div className="button-container">
                    <button disabled={isButtonDisabled} className='default-button modifier-form-annuler' onClick={() => close()}>Annuler</button>
                    <input disabled={isButtonDisabled} className="default-button modifier-form-valider" type="submit" value="modifier" />
                </div>
            </form>
        </div>
    );
};

export default ModifierSalarieAbsenceAdmin;