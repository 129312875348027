import React, { useState } from 'react';
import { getAllDaysOfWeek } from '../../utils/DateHelper';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import JourReleveHeureAdmin from '../../components/tables/JourReleveHeureAdmin';
import Modal from '../../components/Modal';
import ModifierSalarieHeureAdmin from '../../components/forms/ReleveHeureForms/ModifierSalarieHeureAdmin';
import CopierVersJourAdmin from '../../components/CopierVersJourAdmin';
import ModifierChantier from '../../components/forms/ModifierChantier';
import axiosInstance from '../../utils/axiosInstance';
import * as actionCreators from '../../store/actions/exportAction';
import { useDispatch } from 'react-redux';
import customToast from '../../utils/ToastifyHelper';
import ModifierSalarieAbsenceAdmin from '../../components/forms/ReleveHeureForms/ModifierSalarieAbsenceAdmin';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';
import { showError } from '../../utils/ErrorHelper';

const changeStatut = (idSemaine, numStatut) => (actionCreators.changeStatutSemaine(idSemaine, numStatut));

const LiReleveHeureConsultation = (props) => {
    const dispatch = useDispatch();
    const allDaysInWeek = getAllDaysOfWeek(props.releveHeure.numero_semaine, props.releveHeure.annee);

    // GESTION MODAL

    const [modalClasses, setModalClasses] = useState("modal");
    const [modalContent, setModalContent] = useState("Aucun contenu");

    const openDeleteHeure = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerHoraireSalarieAdmin" idChef={props.releveHeure.chef_chantier} numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} salarieHoraire={salarieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerSalarieAbsence = (salarieData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerAbsenceSalarieAdmin" idChef={props.releveHeure.chef_chantier} numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} salarieHoraire={salarieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarieHeure = (jourSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieHeureAdmin numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} jourSalarie={jourSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierChantier chefChantierId={props.releveHeure.chef_chantier} userRole="admin" numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} jourChantier={jourChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeures = (jourData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresAdmin" idChef={props.releveHeure.chef_chantier} numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} jourData={jourData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openSupprimerTouteHeuresChantier = (jourChantier) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresChantiersAdmin" numeroSemaine={props.releveHeure.numero_semaine} idChef={props.releveHeure.chef_chantier} annee={props.releveHeure.annee} jourChantier={jourChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const openModifierSalarieAbsence = (jourSalarie) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<ModifierSalarieAbsenceAdmin numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} jourSalarie={jourSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };


    const openCopierVers = (jourData) => {
        setModalClasses("modal modal__active");
        setTimeout(() => {
            setModalClasses("modal modal__active modal__fade");
        }, 0.1);
        setModalContent(<CopierVersJourAdmin statutSemaine={props.statutSemaine} numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} jourData={jourData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
    };

    const tbodyReleveHeure = <tbody>{allDaysInWeek.map((jour) =>
        <JourReleveHeureAdmin idChef={props.releveHeure.chef_chantier} copierHoraireVers={(jourData) => openCopierVers(jourData)} modifierAbsenceSalarie={(jourSalarie) => openModifierSalarieAbsence(jourSalarie)} modifierChantier={(jourChantier) => openModifierChantier(jourChantier)} supprimerHeuresChantier={(jourChantier) => openSupprimerTouteHeuresChantier(jourChantier)} supprimerTouteHoraires={(jourData) => openSupprimerTouteHeures(jourData)} modifierHeureSalarie={(jourSalarie) => openModifierSalarieHeure(jourSalarie)} supprimerSalarieHeure={(salarieData) => openDeleteHeure(salarieData)} supprimerAbsenceSalarie={(salarieData) => openSupprimerSalarieAbsence(salarieData)} numeroSemaine={props.releveHeure.numero_semaine} annee={props.releveHeure.annee} statutSemaine={props.releveHeure.statut_horaire_semaine} key={jour} dateJour={jour} allDays={allDaysInWeek} jours={props.releveHeure.jours} />
    )}</tbody>;

    const verouillerSemaine = () => {
        axiosInstance.patch(`/horaires_semaines/change_statut/${props.releveHeure.chef_chantier}&${props.releveHeure.annee}&${props.releveHeure.numero_semaine}`, { statut_horaire_semaine: 1 })
            .then(() => {
                customToast.success("Verouillage de la semaine");
                dispatch(changeStatut(props.releveHeure._id, 1));
            }).catch((err) => {
                showError(err);
            });
    };

    const deverouillerSemaine = () => {
        axiosInstance.patch(`/horaires_semaines/change_statut/${props.releveHeure.chef_chantier}&${props.releveHeure.annee}&${props.releveHeure.numero_semaine}`, { statut_horaire_semaine: 0 })
            .then(() => {
                customToast.success("Déverouillage de la semaine");
                dispatch(changeStatut(props.releveHeure._id, 0));
            }).catch((err) => {
                showError(err);
            });
    };

    return (
        <>
            <div className="releveConsultation-top">
                <h2>Relevé du {format(allDaysInWeek[0], 'eeee dd', { locale: fr })} au {format(allDaysInWeek[allDaysInWeek.length - 1], 'dd MMMM yyyy', { locale: fr })}</h2>
                <div className="validerButtonContainer">
                    <button onClick={() => verouillerSemaine()} disabled={props.releveHeure.statut_horaire_semaine} className={!props.releveHeure.statut_horaire_semaine ? 'validerSemaine-button' : 'validerSemaine-button disabled-button'}><p>Verrouiller la semaine</p></button>
                    <button onClick={() => deverouillerSemaine()} disabled={!props.releveHeure.statut_horaire_semaine} className={props.releveHeure.statut_horaire_semaine ? 'devaliderSemaine-button' : 'devaliderSemaine-button disabled-button'}><p>Déverrouiller la semaine</p></button>
                </div>
            </div>
            <div className="releve-heure-table-container admin-releve-table">
                <table className="releve-heure-chef-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Nom du chantier</th>
                            <th>Zone</th>
                            <th>Nombre d'heures saisi</th>
                            <th>Etat</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {tbodyReleveHeure}
                </table>
            </div>
            <Modal modalClass={modalClasses}>{modalContent}</Modal>
        </>
    );
};

export default LiReleveHeureConsultation;