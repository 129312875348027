import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { getWeekNumber, getAllDaysOfWeek, getMaxWeekInYear } from "../../utils/DateHelper";
import * as actionCreators from '../../store/actions/exportAction';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import JourReleveHeure from '../../components/tables/JourReleveHeure';
import Modal from '../../components/Modal';
import ValiderSemaineChef from '../../components/forms/ReleveHeureForms/ValiderSemaineChef';
import CopierVersJour from '../../components/CopierVersJour';
import ModifierChantier from '../../components/forms/ModifierChantier';
import ModifierSalarieHeure from '../../components/forms/ReleveHeureForms/ModifierSalarieHeure';
import ModifierSalarieAbsence from '../../components/forms/ReleveHeureForms/ModifierSalarieAbsence';
import SupprimerModalForm from '../../components/forms/SupprimerModalForm';


const onLoadPage = (dispatch) => {
  dispatch(actionCreators.getUserList());
  // releve heure au numero semaine et a la date
  dispatch(actionCreators.getChantierZoneListReleve());
  dispatch(actionCreators.getAbsenceList());
  dispatch(actionCreators.getChantierOptions());
  // liste des absences laps de temps semaines 
};

const onQuitPage = (dispatch) => {
  dispatch(actionCreators.unloadUserList());
  dispatch(actionCreators.unloadReleveHeure());
  dispatch(actionCreators.unloadChantierZoneList());
  // dispatch unload currentyearstate
  dispatch(actionCreators.resetCurrentWeek());
  dispatch(actionCreators.unloadAbsenceList());
};

const loadHoraireOnChange = (annee, numero_semaine) => (actionCreators.getReleveHeureListMe(annee, numero_semaine));
const setCurrentWeek = (currentWeekNumber, yearNumber, maxWeekInYear) => (actionCreators.changeCurrentWeek(currentWeekNumber, yearNumber, maxWeekInYear));

const ReleveHeureChef = () => {
  const dispatch = useDispatch();
  // ON PAGE LOAD QUIT
  useEffect(() => {
    onLoadPage(dispatch);
    return () => {
      onQuitPage(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [allDaysInWeek, setAllDaysInWeek] = useState([]);
  const currentWeekState = useSelector(
    state => state.currentWeek
  );
  // const [currentWeek, setCurrentWeek] = useState({ 
  //   weekNumber: getWeekNumber(new Date()), 
  //   year: { 
  //     yearNumber: getYear(new Date()),
  //     maxWeekInYear: getWeekNumber(new Date(new Date().getFullYear(), 11, 31))
  //   }
  // });

  useEffect(() => {
    setAllDaysInWeek(getAllDaysOfWeek(currentWeekState.weekNumber, currentWeekState.year.yearNumber));
    dispatch(loadHoraireOnChange(currentWeekState.year.yearNumber, currentWeekState.weekNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeekState]);

  // button numero semaine
  const decrWeekNumber = () => {
    if (currentWeekState.weekNumber === 1) {
      const maxWeekInYear = getMaxWeekInYear(currentWeekState.year.yearNumber - 1);
      dispatch(setCurrentWeek(maxWeekInYear, currentWeekState.year.yearNumber - 1, maxWeekInYear));
    }
    else {
      dispatch(setCurrentWeek(currentWeekState.weekNumber - 1, currentWeekState.year.yearNumber, currentWeekState.year.maxWeekInYear));
    }
  };

  const incrWeekNumber = () => {
    if (currentWeekState.weekNumber === currentWeekState.year.maxWeekInYear) {
      const maxWeekInYear = getMaxWeekInYear(currentWeekState.year.yearNumber + 1);
      dispatch(setCurrentWeek(1, currentWeekState.year.yearNumber + 1, maxWeekInYear));
    }
    else {
      dispatch(setCurrentWeek(currentWeekState.weekNumber + 1, currentWeekState.year.yearNumber, currentWeekState.year.maxWeekInYear));
    }
  };
  // render les jours
  // GESTION MODAL

  const [modalClasses, setModalClasses] = useState("modal");
  const [modalContent, setModalContent] = useState("Aucun contenu");

  const openDeleteHeure = (salarieData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<SupprimerModalForm destination="supprimerHoraireSalarie" currentWeekState={currentWeekState} salarieHoraire={salarieData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openSupprimerSalarieAbsence = (salarieData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<SupprimerModalForm salarieHoraire={salarieData} destination="supprimerAbsenceSalarie" currentWeekState={currentWeekState} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openValiderSemaine = (semaineData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<ValiderSemaineChef semaineData={semaineData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openCopierVers = (jourData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<CopierVersJour numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourData={jourData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openSupprimerTouteHeures = (jourData) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<SupprimerModalForm destination="supprimerToutesHeures" numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourData={jourData} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openSupprimerTouteHeuresChantier = (jourChantier) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<SupprimerModalForm destination="supprimerToutesHeuresChantiers" numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourChantier={jourChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openModifierChantier = (jourChantier) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<ModifierChantier numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourChantier={jourChantier} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openModifierSalarieHeure = (jourSalarie) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<ModifierSalarieHeure numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourSalarie={jourSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const openModifierSalarieAbsence = (jourSalarie) => {
    setModalClasses("modal modal__active");
    setTimeout(() => {
      setModalClasses("modal modal__active modal__fade");
    }, 0.1);
    setModalContent(<ModifierSalarieAbsence numeroSemaine={currentWeekState.weekNumber} annee={currentWeekState.year.yearNumber} jourSalarie={jourSalarie} closeModal={() => setModalClasses("modal")} unrenderForm={() => setModalContent("Aucune modal")} />);
  };

  const statutSemaineState = useSelector(
    state => state.releveHeure.statut_horaire_semaine
  );

  const tbodyReleveHeure = <tbody>{allDaysInWeek.map((jour) =>
    <JourReleveHeure modifierHeureSalarie={(jourSalarie) => openModifierSalarieHeure(jourSalarie)} modifierAbsenceSalarie={(jourSalarie) => openModifierSalarieAbsence(jourSalarie)} modifierChantier={(jourChantier) => openModifierChantier(jourChantier)} supprimerHeuresChantier={(jourChantier) => openSupprimerTouteHeuresChantier(jourChantier)} supprimerTouteHoraires={(jourData) => openSupprimerTouteHeures(jourData)} copierHoraireVers={(jourData) => openCopierVers(jourData)} statutSemaine={statutSemaineState} supprimerSalarieHeure={(salarieData) => openDeleteHeure(salarieData)} supprimerAbsenceSalarie={(salarieData) => openSupprimerSalarieAbsence(salarieData)} key={jour} dateJour={jour} allDays={allDaysInWeek} />
  )}</tbody>;


  const [buttonWidth, setButtonWidth] = useState(0);
  const chooseNumeroSemaineRef = useRef();

  useLayoutEffect(() => {
    setButtonWidth(chooseNumeroSemaineRef.current.offsetWidth);
  }, []);

  return (
    <div className="section">
      <div className="relevee-heure-chef-top-container container-basic mgB-m2">
        <div className="titleSemaineAndValidate-container">
          <h1 className='h1-blue-dark'>Relevé d'heures</h1>
          <button style={{ width: buttonWidth }} disabled={statutSemaineState === 0 || statutSemaineState === null ? false : true} className={statutSemaineState === 0 || statutSemaineState === null ? "validate-button-fw" : "validate-button-fw disabled-button"} onClick={() => openValiderSemaine({ annee: currentWeekState.year.yearNumber, numeroSemaine: currentWeekState.weekNumber })} value="Valider">Valider la semaine</button>
        </div>
        <div className="relevee-heure-chef-topSection">
          {allDaysInWeek.length > 0 && <h2 className='h2-light'>Du {format(allDaysInWeek[0], 'eeee dd', { locale: fr })} au {format(allDaysInWeek[allDaysInWeek.length - 1], 'dd MMMM yyyy', { locale: fr })}</h2>}
          <div ref={chooseNumeroSemaineRef} className="semaine-selection">
            <button onClick={() => decrWeekNumber()} className='select-semaine-button select-semaine-button-left'><Icon className="select-semaine-left" icon="bx:left-arrow" /></button>
            <p className='currentWeek'>Semaine {currentWeekState.weekNumber}</p>
            <button onClick={() => incrWeekNumber()} className='select-semaine-button select-semaine-button-right'><Icon className="select-semaine-right" icon="bx:right-arrow" /></button>
          </div>
        </div>
      </div>
      <div className={statutSemaineState === 0 || statutSemaineState === null ? "releve-heure-table-container" : "releve-heure-table-container semaine-valider"}>
        <table className="releve-heure-chef-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Nom du chantier</th>
              <th>Zone</th>
              <th>Nombre d'heures saisi</th>
              <th>Etat</th>
              <th>Actions</th>
            </tr>
          </thead>
          {tbodyReleveHeure}
        </table>
      </div>
      <Modal modalClass={modalClasses}>{modalContent}</Modal>
    </div>

  );
};

export default ReleveHeureChef;
